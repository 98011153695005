import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';
import type { AppThunk } from 'src/store'
import { apiConfig } from 'src/config';
import axios from 'src/utils/axios';

interface PersonalStatisticsState {
    averageSalePrice,
    withdrawnExpired,
    activeListings,
    closedSales,
    listPriceOverTime: {
        data,
        labels
    },
    newListingsOverTime: {
        data,
        labels
    },
    filters: {
        state,
        county,
        time,
    },
    timeRange,
    state,
    county
}

const initialState: PersonalStatisticsState = {
    averageSalePrice: null,
    withdrawnExpired: null,
    activeListings: null,
    closedSales: null,
    listPriceOverTime: {
        data: null,
        labels: null
    },
    newListingsOverTime: {
        data: null,
        labels: null
    },
    filters: {
        state: null,
        county: null,
        time: null,
    },
    timeRange: null,
    state: null,
    county: null
};

const slice = createSlice({
    name: 'PersonalStatistics',
    initialState,
    reducers: {

        getPersonalAverageSalePrice(state: PersonalStatisticsState, action: PayloadAction<{averageSalePrice}>) {
            let data = action.payload;
            state = { ...state, averageSalePrice: data.averageSalePrice }
            return state;
        },

        getPersonalWithdrawnExpired(state: PersonalStatisticsState, action: PayloadAction<{withdrawnExpired}>) {
            let data = action.payload;
            state = { ...state, withdrawnExpired: data.withdrawnExpired }
            return state;
        },

        getPersonalActiveListings(state: PersonalStatisticsState, action: PayloadAction<{activeListings}>) {
            let data = action.payload;
            state = { ...state, activeListings: data.activeListings }
            return state;
        },

        getPersonalClosedSales(state: PersonalStatisticsState, action: PayloadAction<{closedSales}>) {
            let data = action.payload;
            state = { ...state, closedSales: data.closedSales }
            return state;
        },

        getPersonalListPriceOverTime(state: PersonalStatisticsState, action: PayloadAction<{listPriceOverTime}>) {
            let data = action.payload;
            state = { 
                ...state, 
                listPriceOverTime: {
                    data: data.listPriceOverTime.data,
                    labels: data.listPriceOverTime.labels
                }, 
            }
            return state;
        },

        getPersonalNewListingsOverTime(state: PersonalStatisticsState, action: PayloadAction<{newListingsOverTime}>) {
            let data = action.payload;
            state = { 
                ...state, 
                newListingsOverTime: {
                    data: data.newListingsOverTime.data,
                    labels: data.newListingsOverTime.labels
                }, 
            }
            return state;
        },

        setPersonalFilters(state: PersonalStatisticsState, action: PayloadAction<{ filters }>) {
            const data = action.payload;

            state = {
                ...state,
                filters: {
                    state: data.filters.state,
                    county: data.filters.county,
                    time: data.filters.time,
                }
            }

            return state;
        },
        setPersonalTimeRange(state: PersonalStatisticsState, action: PayloadAction<string>) {
            state.timeRange = action.payload
            return state;
        },
        setPersonalState(state: PersonalStatisticsState, action: PayloadAction<string>) {
            state.state = action.payload
            return state;
        },
        setPersonalCounty(state: PersonalStatisticsState, action: PayloadAction<string>) {
            state.county = action.payload
            return state;
        },
        resetPersonalStatsState(state: PersonalStatisticsState, action: PayloadAction<string>) {
            state[action.payload] = {
                ...state[action.payload],
                averageSalePrice: undefined,
                withdrawnExpired: undefined,
                activeListings: undefined,
                closedSales: undefined,
                listPriceOverTime: {
                    data: undefined,
                    labels: undefined
                },
                newListingsOverTime: {
                    data: undefined,
                    labels: undefined
                }
            };
            return state
        }
    },
});

export const reducer = slice.reducer;

export const getPersonalAverageSalePrice = (userId: string, state: PersonalStatisticsState): AppThunk => async (dispatch) => {
    let averageSaleRes = await axios.get<{ stats: { data } }>(`//${apiConfig.api_prefix}/statistics/averageListPrice/${userId}`, {
        params: {
            method: "personal",
            dateRange: `${state.timeRange}`,
            state: `${state.state}`,
            county: `${state.county}`
        }
    });
    
    dispatch(slice.actions.getPersonalAverageSalePrice({ averageSalePrice: averageSaleRes.data.stats.data }));
};

export const getPersonalWithdrawnExpired = (userId: string, state: PersonalStatisticsState): AppThunk => async (dispatch) => {
    let expiredRes = await axios.get<{ stats: { data } }>(`//${apiConfig.api_prefix}/statistics/expired/${userId}`, {
        params: {
            method: "personal",
            dateRange: `${state.timeRange}`,
            state: `${state.state}`,
            county: `${state.county}`
        }
    });
    let withdrawnRes = await axios.get<{ stats: { data } }>(`//${apiConfig.api_prefix}/statistics/withdrawn/${userId}`, {
        params: {
            method: "personal",
            dateRange: `${state.timeRange}`,
            state: `${state.state}`,
            county: `${state.county}`
        }
    });
    
    dispatch(slice.actions.getPersonalWithdrawnExpired({ withdrawnExpired: expiredRes.data.stats.data + withdrawnRes.data.stats.data }));
};

export const getPersonalActiveListings = (userId: string, state: PersonalStatisticsState): AppThunk => async (dispatch) => {
    let activeListingsRes = await axios.get<{ stats: { data } }>(`//${apiConfig.api_prefix}/statistics/activeListings/${userId}`, {
        params: {
            method: "personal",
            dateRange: `${state.timeRange}`,
            state: `${state.state}`,
            county: `${state.county}`
        }
    });
    
    dispatch(slice.actions.getPersonalActiveListings({ activeListings: activeListingsRes.data.stats.data, }));
};

export const getPersonalClosedSales = (userId: string, state: PersonalStatisticsState): AppThunk => async (dispatch) => {
    let closedRes = await axios.get<{ stats: { data } }>(`//${apiConfig.api_prefix}/statistics/closedSales/${userId}`, {
        params: {
            method: "personal",
            dateRange: `${state.timeRange}`,
            state: `${state.state}`,
            county: `${state.county}`
        }
    });

    dispatch(slice.actions.getPersonalClosedSales({ closedSales: closedRes.data.stats.data }));
};

export const getPersonalListPriceOverTime = (userId: string, state: PersonalStatisticsState): AppThunk => async (dispatch) => {
    let priceOverTimeRes = await axios.get<{ stats: { data, labels }; }>(`//${apiConfig.api_prefix}/statistics/propertyListPrice/${userId}`, {
        params: {
            method: "personal",
            dateRange: `${state.timeRange}`,
            state: `${state.state}`,
            county: `${state.county}`
        }
    });

    dispatch(slice.actions.getPersonalListPriceOverTime({ 
        listPriceOverTime: {
            data: priceOverTimeRes.data.stats.data,
            labels: priceOverTimeRes.data.stats.labels,
        },
    }));
};

export const getPersonalNewListingsOverTime = (userId: string, state: PersonalStatisticsState): AppThunk => async (dispatch) => {
    let newListingsRes = await axios.get<{ stats: { data, labels }; }>(`//${apiConfig.api_prefix}/statistics/activeListingsOverTime/${userId}`, {
        params: {
            method: "personal",
            dateRange: `${state.timeRange}`,
            state: `${state.state}`,
            county: `${state.county}`
        }
    });

    dispatch(slice.actions.getPersonalNewListingsOverTime({
        newListingsOverTime: {
            data: newListingsRes.data.stats.data,
            labels: newListingsRes.data.stats.labels,
        }
    }));
};

export const setPersonalTimeRange = (timeRange: string, index: number, userId: string): AppThunk => async (dispatch, getState) => {
    dispatch(slice.actions.setPersonalTimeRange(timeRange));
    dispatch(slice.actions.setPersonalFilters({
        filters: {
            time: index,
            state: getState().personalStatistics.filters.state,
            county: getState().personalStatistics.filters.county
        }
    }));
    dispatch(slice.actions.resetPersonalStatsState('personal'));
    reset(userId, dispatch, getState);
};

export const setPersonalState = (state: string, stateFilter: any, userId: string): AppThunk => async (dispatch, getState) => {
    dispatch(slice.actions.setPersonalState(state));
    dispatch(slice.actions.setPersonalFilters({
        filters: {
            time: getState().personalStatistics.filters.time || 2,
            state: stateFilter,
            county: getState().personalStatistics.filters.county
        }
    }));
    dispatch(slice.actions.resetPersonalStatsState('personal'));
    reset(userId, dispatch, getState);
};

export const setPersonalCounty = (county: string, countyFilter: any, userId: string): AppThunk => async (dispatch, getState) => {
    dispatch(slice.actions.setPersonalCounty(county));
    dispatch(slice.actions.setPersonalFilters({
        filters: {
            time: getState().personalStatistics.filters.time || 2,
            state: getState().personalStatistics.filters.state,
            county: countyFilter
        }
    }));
    dispatch(slice.actions.resetPersonalStatsState('personal'));
    reset(userId, dispatch, getState);
};

const reset = (userId, dispatch, getState) => {
    dispatch(getPersonalAverageSalePrice(userId, getState().personalStatistics));
    dispatch(getPersonalWithdrawnExpired(userId, getState().personalStatistics));
    dispatch(getPersonalActiveListings(userId, getState().personalStatistics));
    dispatch(getPersonalClosedSales(userId, getState().personalStatistics));
    dispatch(getPersonalListPriceOverTime(userId, getState().personalStatistics));
    dispatch(getPersonalNewListingsOverTime(userId, getState().personalStatistics));
};

export default slice;
