import React from 'react';
import type { FC, ReactNode } from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuth from '../hooks/useAuth';

interface GuestGuardProps {
  children?: ReactNode;
}

const GuestGuard: FC<GuestGuardProps> = ({ children }) => {
  const { isAuthenticated, err } = useAuth();

  if (isAuthenticated) {
    if(err.status === 403){
      return <Redirect to="/404" /> //Replace with "your account isn't active yet" page
    }
    return <Redirect to="/app/landing" />;
  }

  

  return (
    <>
      {children}
    </>
  );
};

GuestGuard.propTypes = {
  children: PropTypes.node
};

export default GuestGuard;
