import React from 'react';
import type { FC } from 'react';
import useSettings from 'src/hooks/useSettings';
import {useLocation} from 'react-router-dom';


interface LogoProps {
  [key: string]: any;
}

const LogoSmall: FC<LogoProps> = (props) => {  
  const { settings } = useSettings();
  // const location = useLocation();
  if(settings.theme !== "DARK"){ //if on light theme and the main page, return dark logo (only place with light header bar). Otherwise, return light.
    return (
    <img
      alt="Logo"
      // src="/static/logo.svg"
      src="/static/CoOpLogo_Large.png"
      style={{maxHeight: "40px"}}
      {...props}
    />)
  }
  else {
    return (
    <img
      alt="Logo"
      // src="/static/logo.svg"
      src="/static/lbmlsLightTextLogo.svg"
      style={{maxHeight: "40px"}}
      {...props}
    />)
    }
}

export default LogoSmall;
