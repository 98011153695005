import moment from 'moment';
import mock from 'src/utils/mock';
import type { Need } from 'src/types/need';

let needs: Need[] = [
  // {
  //   id: '1',
  //   attributes: ['In Progress'],
  //   category: 'mineral',
  //   currency: '$',
  //   createdAt: moment()
  //     .subtract(1, 'days')
  //     .toDate()
  //     .getTime(),
  //   image: null,
  //   inventoryType: 'appraisal',
  //   isAvailable: true,
  //   isShippable: false,
  //   name: 'Huge Ranch 1 million acres',
  //   price: 21200000,
  //   quantity: 85,
  //   updatedAt: moment()
  //     .subtract(6, 'hours')
  //     .toDate()
  //     .getTime(),
  //   variants: 2
  // },
  // {
  //   id: '2',
  //   attributes: ['Under contract'],
  //   category: 'solar',
  //   currency: '$',
  //   createdAt: moment()
  //     .subtract(3, 'days')
  //     .toDate()
  //     .getTime(),
  //   image: null,
  //   inventoryType: 'lease',
  //   isAvailable: false,
  //   isShippable: true,
  //   name: 'Lousiana Purchase',
  //   price: 950000000,
  //   quantity: 0,
  //   updatedAt: moment()
  //     .subtract(2, 'days')
  //     .subtract(8, 'hours')
  //     .toDate()
  //     .getTime(),
  //   variants: 1
  // },
  // {
  //   id: '3',
  //   attributes: ['Available'],
  //   category: 'mineral',
  //   currency: '$',
  //   createdAt: moment()
  //     .subtract(6, 'days')
  //     .toDate()
  //     .getTime(),
  //   image: null,
  //   inventoryType: 'property_rights',
  //   isAvailable: true,
  //   isShippable: false,
  //   name: 'Van down by the river',
  //   price: 155000,
  //   quantity: 48,
  //   updatedAt: moment()
  //     .subtract(1, 'days')
  //     .subtract(2, 'hours')
  //     .toDate()
  //     .getTime(),
  //   variants: 5
  // },
  // {
  //   id: '4',
  //   attributes: ['Available'],
  //   category: 'appraisal',
  //   currency: '$',
  //   createdAt: moment()
  //     .subtract(12, 'days')
  //     .toDate()
  //     .getTime(),
  //   image: null,
  //   inventoryType: 'private_listing',
  //   isAvailable: false,
  //   isShippable: true,
  //   name: 'The Annexation of Puerto Rico',
  //   price: 1799999,
  //   quantity: 5,
  //   updatedAt: moment()
  //     .subtract(1, 'days')
  //     .subtract(7, 'hours')
  //     .toDate()
  //     .getTime(),
  //   variants: 1
  // }
];

mock.onGet('/mock/needs').reply(200, { needs });
