import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';
import type { AppThunk } from 'src/store'
import { apiConfig } from 'src/config';
import axios from 'src/utils/axios';
import type { Need } from 'src/types/need';
import type { Search } from 'src/types/search';
import numeral from 'numeral';

interface NeedState {
  management: {
    filters: {
      query,
      mode,
      ptype,
      minPrice,
      maxPrice,
      minAcre,
      maxAcre,
      creationDateMin,
      creationDateMax,
      Features,
      state,
      county,
      listingStatus
    },
    data
  },
  search: {
   filters: {
    query,
    mode,
    ptype,
    minPrice,
    maxPrice,
    minAcre,
    maxAcre,
    creationDateMin,
    creationDateMax,
    Features,
    state,
    county,
    listingStatus,
   },
   data
  },
  saved: {
    searches
  }
};

const initialState: NeedState = {
 management: {
   filters: {
    query: "",
    mode: null,
    ptype: [],
    minPrice: null,
    maxPrice: null,
    minAcre: null,
    maxAcre: null,
    creationDateMin: null,
    creationDateMax: null,
    Features: [],
    state: null,
    county: null,
    listingStatus: null
  },
  data: []
  },
  search: {
    filters: {
     query: "",
     mode: null,
     ptype: [],
     minPrice: null,
     maxPrice: null,
     minAcre: null,
     maxAcre: null,
     creationDateMin: null,
     creationDateMax: null,
     Features: [],
     state: null,
     county: null,
     listingStatus: null,
   },
   data: []
   },
  saved: {
    searches: []
  }
};

const slice = createSlice({
  name: 'need',
  initialState,
  reducers: {
    getManagementNeeds(state: NeedState, action: PayloadAction<{needs: Need[]}>){
      action.payload.needs.map(e => {
        if(e.lpriceMin){
          if(e.lpriceMax){
            e.formattedPrice = `${numeral(e.lpriceMin).format(`$0,0`)} through ${numeral(e.lpriceMax).format(`$0,0`)}`
          } else e.formattedPrice = `Min: ${numeral(e.lpriceMin).format(`$0,0`)}`;
        } else if (e.lpriceMax){
          e.formattedPrice = `Max: ${numeral(e.lpriceMax).format(`$0,0`)}`;
        } else e.formattedPrice = '';

        if(e.state && e.state != ""){
          if(e.city && e.city != ""){
            if(e.address1 && e.address1 != ""){
              e.formattedLocation = `${e.address1}, ${e.city}, ${e.state}` //address, city, state
            }else e.formattedLocation = `${e.city}, ${e.state}` //no supplied address
          }else e.formattedLocation = `${e.state}` //no supplied city
        } //no supplied state means don't show anything.
      })
      const newState = { management: {data: []} }
      newState.management.data = action.payload.needs;
      return Object.assign({}, state, newState);
    },
    getSearchNeeds(state: NeedState, action: PayloadAction<{needs: Need[]}>){
      action.payload.needs.map(e => {

        if(e.acreageMin){
          if(e.acreageMax){
            e.formattedAcreage = `${e.acreageMin} through ${e.acreageMax}`
          } else e.formattedAcreage = `Min: ${e.acreageMin}`;
        } else if (e.acreageMax){
          e.formattedAcreage = `Max: ${e.acreageMax}`;
        } else e.formattedAcreage = '';

        if(e.lpriceMin){
          if(e.lpriceMax){
            e.formattedPrice = `${numeral(e.lpriceMin).format(`$0,0`)} through ${numeral(e.lpriceMax).format(`$0,0`)}`
          } else e.formattedPrice = `Min: ${numeral(e.lpriceMin).format(`$0,0`)}`;
        } else if (e.lpriceMax){
          e.formattedPrice = `Max: ${numeral(e.lpriceMax).format(`$0,0`)}`;
        } else e.formattedPrice = '';

        if(e.state && e.state != ""){
          if(e.city && e.city != ""){
            if(e.address1 && e.address1 != ""){
              e.formattedLocation = `${e.address1}, ${e.city}, ${e.state}` //address, city, state
            }else e.formattedLocation = `${e.city}, ${e.state}` //no supplied address
          }else e.formattedLocation = `${e.state}` //no supplied city
        } //no supplied state means don't show anything.
      })
      const newState = { search: {data: []} }
      newState.search.data = action.payload.needs;
      return Object.assign({}, state, newState);
    },
    setManagementFilters(state: NeedState, action: PayloadAction<{
      query,
     mode,
     ptype,
     minPrice,
     maxPrice,
     minAcre,
     maxAcre,
     creationDateMin,
     creationDateMax,
     Features,
     state,
     county,
     listingStatus
    }>) 
      {
      let data = action.payload;
      state = {
        ...state,
        management: {
          filters: {
            query: data.query,
            mode: "management",
            ptype: data.ptype,
            minPrice: data.minPrice,
            maxPrice: data.maxPrice,
            minAcre: data.minAcre,
            maxAcre: data.maxAcre,
            creationDateMin: data.creationDateMin,
            creationDateMax: data.creationDateMax,
            Features: data.Features,
            state: data.state,
            county: data.county,
          },
          ...state.management
        }
      };
    },
    setManagementQuery(state: NeedState, action: PayloadAction<{query}>) {
      state.management.filters = {
        ...state.management.filters,
        query: action.payload.query
      };
      return state;
    },
    setSearchFilters(state: NeedState, action: PayloadAction<{
     query,
     mode,
     ptype,
     minPrice,
     maxPrice,
     minAcre,
     maxAcre,
     creationDateMin,
     creationDateMax,
     Features,
     state,
     county,
     listingStatus,
    }>) 
      {
      let data = action.payload;
      state.search.filters = {
        ...state.search.filters,
        ...data
      };
      return state;
    },
    setSearchQuery(state: NeedState, action: PayloadAction<{query}>) {
      state.search.filters = {
        ...state.search.filters,
        query: action.payload.query
      };
      return state;
    }
}});

export const reducer = slice.reducer;

export const getNeeds = (state: NeedState, mode: string): AppThunk => async (dispatch) => {
  try{
    if(mode == "management"){
      let propResponse = await axios.get<{needs: Need[]; }>(`//${apiConfig.api_prefix}/need/`,{
        params: {
          query: state.search.filters.query,
          ...state.search.filters,
          mode: "management",
          state: state.search.filters.state,
          types: state.search.filters["Need Types"],
          minPrice: state.search.filters["Min Price"],
          maxPrice: state.search.filters["Max Price"],
          minAcre: state.search.filters["Min Acreage"],
          maxAcre: state.search.filters["Max Acreage"],
          listDateMin: state.search.filters["Creation Date Min"],
          listDateMax: state.search.filters["Creation Date Max"]
        }
      });
      dispatch(slice.actions.getManagementNeeds({needs: propResponse.data.needs}));
    }
    if(mode == "search"){
      let propResponse = await axios.get<{needs: Need[]; }>(`//${apiConfig.api_prefix}/need/`,{
        params: {
          query: state.search.filters.query,
          ...state.search.filters,
          mode: "search",
          state: state.search.filters.state,
          ptype: state.search.filters["Need Types"],
          minPrice: state.search.filters["Min Price"],
          maxPrice: state.search.filters["Max Price"],
          minAcre: state.search.filters["Min Acreage"],
          maxAcre: state.search.filters["Max Acreage"],
          creationDateMin: state.search.filters["List Date Min"],
          creationDateMax: state.search.filters["List Date Max"],
        }
      });
      dispatch(slice.actions.getSearchNeeds({needs: propResponse.data.needs}));
    }
  }
  catch(err){
    console.log(err);
  }
};

export const setSearchFilters = (filters: { 
  query,
  mode,
  ptype,
  minPrice,
  maxPrice,
  minAcre,
  maxAcre,
  creationDateMin,
  creationDateMax,
  Features,
  state,
  county,
  listingStatus,
  }): AppThunk => async (dispatch, getState) => {
  dispatch(slice.actions.setSearchFilters(filters));
  dispatch(getNeeds(getState().need, "search"));
  }

export const setManagementFilters = (filters: { 
  query,
  mode,
  ptype,
  minPrice,
  maxPrice,
  minAcre,
  maxAcre,
  creationDateMin,
  creationDateMax,
  Features,
  state,
  county,
  listingStatus}): AppThunk => async (dispatch, getState) => {
  dispatch(slice.actions.setManagementFilters(filters));
  dispatch(getNeeds(getState().need, "management")); //Use getState to send newly update state with timeRange
}

export const setSearchQuery = (query: string): AppThunk => async (dispatch, getState) => {
  dispatch(slice.actions.setSearchQuery({query}));
  dispatch(getNeeds(getState().need, "search"));
}

export const setManagementQuery = (query: string): AppThunk => async (dispatch, getState) => {
  dispatch(slice.actions.setManagementQuery({query}));
  dispatch(getNeeds(getState().need, "search"));
}

export default slice;
