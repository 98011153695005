import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';
import type { AppThunk } from 'src/store'
import { apiConfig } from 'src/config';
import axios from 'src/utils/axios';

interface BrokerageStatisticsState {
    averageSalePrice,
    withdrawnExpired,
    activeListings,
    closedSales,
    listPriceOverTime: {
        data,
        labels
    },
    newListingsOverTime: {
        data,
        labels
    },
    filters: {
        state,
        county,
        time,
    },
    timeRange,
    state,
    county
}

const initialState: BrokerageStatisticsState = {
    averageSalePrice: null,
    withdrawnExpired: null,
    activeListings: null,
    closedSales: null,
    listPriceOverTime: {
        data: null,
        labels: null
    },
    newListingsOverTime: {
        data: null,
        labels: null
    },
    filters: {
        state: null,
        county: null,
        time: null,
    },
    timeRange: null,
    state: null,
    county: null
};

const slice = createSlice({
    name: 'BrokerageStatistics',
    initialState,
    reducers: {

        getBrokerageAverageSalePrice(state: BrokerageStatisticsState, action: PayloadAction<{averageSalePrice}>) {
            let data = action.payload;
            state = { ...state, averageSalePrice: data.averageSalePrice }
            return state;
        },

        getBrokerageWithdrawnExpired(state: BrokerageStatisticsState, action: PayloadAction<{withdrawnExpired}>) {
            let data = action.payload;
            state = { ...state, withdrawnExpired: data.withdrawnExpired }
            return state;
        },

        getBrokerageActiveListings(state: BrokerageStatisticsState, action: PayloadAction<{activeListings}>) {
            let data = action.payload;
            state = { ...state, activeListings: data.activeListings }
            return state;
        },

        getBrokerageClosedSales(state: BrokerageStatisticsState, action: PayloadAction<{closedSales}>) {
            let data = action.payload;
            state = { ...state, closedSales: data.closedSales }
            return state;
        },

        getBrokerageListPriceOverTime(state: BrokerageStatisticsState, action: PayloadAction<{listPriceOverTime}>) {
            let data = action.payload;
            state = { 
                ...state, 
                listPriceOverTime: {
                    data: data.listPriceOverTime.data,
                    labels: data.listPriceOverTime.labels
                }, 
            }
            return state;
        },

        getBrokerageNewListingsOverTime(state: BrokerageStatisticsState, action: PayloadAction<{newListingsOverTime}>) {
            let data = action.payload;
            state = { 
                ...state, 
                newListingsOverTime: {
                    data: data.newListingsOverTime.data,
                    labels: data.newListingsOverTime.labels
                }, 
            }
            return state;
        },

        setBrokerageFilters(state: BrokerageStatisticsState, action: PayloadAction<{ filters }>) {
            const data = action.payload;

            state = {
                ...state,
                filters: {
                    state: data.filters.state,
                    county: data.filters.county,
                    time: data.filters.time,
                }
            }

            return state;
        },
        setBrokerageTimeRange(state: BrokerageStatisticsState, action: PayloadAction<string>) {
            state.timeRange = action.payload
            return state;
        },
        setBrokerageState(state: BrokerageStatisticsState, action: PayloadAction<string>) {
            state.state = action.payload
            return state;
        },
        setBrokerageCounty(state: BrokerageStatisticsState, action: PayloadAction<string>) {
            state.county = action.payload
            return state;
        },
        resetBrokerageStatsState(state: BrokerageStatisticsState, action: PayloadAction<string>) {
            state[action.payload] = {
                ...state[action.payload],
                averageSalePrice: undefined,
                withdrawnExpired: undefined,
                activeListings: undefined,
                closedSales: undefined,
                listPriceOverTime: {
                    data: undefined,
                    labels: undefined
                },
                newListingsOverTime: {
                    data: undefined,
                    labels: undefined
                }
            };
            return state
        }
    },
});

export const reducer = slice.reducer;

export const getBrokerageAverageSalePrice = (userId: string, state: BrokerageStatisticsState): AppThunk => async (dispatch) => {
    let averageSaleRes = await axios.get<{ stats: { data } }>(`//${apiConfig.api_prefix}/statistics/averageListPrice/${userId}`, {
        params: {
            method: "brokerage",
            dateRange: `${state.timeRange}`,
            state: `${state.state}`,
            county: `${state.county}`
        }
    });
    
    dispatch(slice.actions.getBrokerageAverageSalePrice({ averageSalePrice: averageSaleRes.data.stats.data }));
};

export const getBrokerageWithdrawnExpired = (userId: string, state: BrokerageStatisticsState): AppThunk => async (dispatch) => {
    let expiredRes = await axios.get<{ stats: { data } }>(`//${apiConfig.api_prefix}/statistics/expired/${userId}`, {
        params: {
            method: "brokerage",
            dateRange: `${state.timeRange}`,
            state: `${state.state}`,
            county: `${state.county}`
        }
    });
    let withdrawnRes = await axios.get<{ stats: { data } }>(`//${apiConfig.api_prefix}/statistics/withdrawn/${userId}`, {
        params: {
            method: "brokerage",
            dateRange: `${state.timeRange}`,
            state: `${state.state}`,
            county: `${state.county}`
        }
    });
    
    dispatch(slice.actions.getBrokerageWithdrawnExpired({ withdrawnExpired: expiredRes.data.stats.data + withdrawnRes.data.stats.data }));
};

export const getBrokerageActiveListings = (userId: string, state: BrokerageStatisticsState): AppThunk => async (dispatch) => {
    let activeListingsRes = await axios.get<{ stats: { data } }>(`//${apiConfig.api_prefix}/statistics/activeListings/${userId}`, {
        params: {
            method: "brokerage",
            dateRange: `${state.timeRange}`,
            state: `${state.state}`,
            county: `${state.county}`
        }
    });
    
    dispatch(slice.actions.getBrokerageActiveListings({ activeListings: activeListingsRes.data.stats.data, }));
};

export const getBrokerageClosedSales = (userId: string, state: BrokerageStatisticsState): AppThunk => async (dispatch) => {
    let closedRes = await axios.get<{ stats: { data } }>(`//${apiConfig.api_prefix}/statistics/closedSales/${userId}`, {
        params: {
            method: "brokerage",
            dateRange: `${state.timeRange}`,
            state: `${state.state}`,
            county: `${state.county}`
        }
    });

    dispatch(slice.actions.getBrokerageClosedSales({ closedSales: closedRes.data.stats.data }));
};

export const getBrokerageListPriceOverTime = (userId: string, state: BrokerageStatisticsState): AppThunk => async (dispatch) => {
    let priceOverTimeRes = await axios.get<{ stats: { data, labels }; }>(`//${apiConfig.api_prefix}/statistics/propertyListPrice/${userId}`, {
        params: {
            method: "brokerage",
            dateRange: `${state.timeRange}`,
            state: `${state.state}`,
            county: `${state.county}`
        }
    });

    dispatch(slice.actions.getBrokerageListPriceOverTime({ 
        listPriceOverTime: {
            data: priceOverTimeRes.data.stats.data,
            labels: priceOverTimeRes.data.stats.labels,
        },
    }));
};

export const getBrokerageNewListingsOverTime = (userId: string, state: BrokerageStatisticsState): AppThunk => async (dispatch) => {
    let newListingsRes = await axios.get<{ stats: { data, labels }; }>(`//${apiConfig.api_prefix}/statistics/activeListingsOverTime/${userId}`, {
        params: {
            method: "brokerage",
            dateRange: `${state.timeRange}`,
            state: `${state.state}`,
            county: `${state.county}`
        }
    });

    dispatch(slice.actions.getBrokerageNewListingsOverTime({
        newListingsOverTime: {
            data: newListingsRes.data.stats.data,
            labels: newListingsRes.data.stats.labels,
        }
    }));
};

export const setBrokerageTimeRange = (timeRange: string, index: number, userId: string): AppThunk => async (dispatch, getState) => {
    dispatch(slice.actions.setBrokerageTimeRange(timeRange));
    dispatch(slice.actions.setBrokerageFilters({
        filters: {
            time: index,
            state: getState().brokerageStatistics.filters.state,
            county: getState().brokerageStatistics.filters.county
        }
    }));
    dispatch(slice.actions.resetBrokerageStatsState('brokerage'));
    reset(userId, dispatch, getState);

};

export const setBrokerageState = (state: string, stateFilter: any, userId: string): AppThunk => async (dispatch, getState) => {
    dispatch(slice.actions.setBrokerageState(state));
    dispatch(slice.actions.setBrokerageFilters({
        filters: {
            time: getState().brokerageStatistics.filters.time || 2,
            state: stateFilter,
            county: getState().brokerageStatistics.filters.county
        }
    }));
    dispatch(slice.actions.resetBrokerageStatsState('brokerage'));
    reset(userId, dispatch, getState);

};

export const setBrokerageCounty = (county: string, countyFilter: any, userId: string): AppThunk => async (dispatch, getState) => {
    dispatch(slice.actions.setBrokerageCounty(county));
    dispatch(slice.actions.setBrokerageFilters({
        filters: {
            time: getState().brokerageStatistics.filters.time || 2,
            state: getState().brokerageStatistics.filters.state,
            county: countyFilter
        }
    }));
    dispatch(slice.actions.resetBrokerageStatsState('brokerage'));
    reset(userId, dispatch, getState);
};

const reset = (userId, dispatch, getState) => {
    dispatch(getBrokerageAverageSalePrice(userId, getState().brokerageStatistics));
    dispatch(getBrokerageWithdrawnExpired(userId, getState().brokerageStatistics));
    dispatch(getBrokerageActiveListings(userId, getState().brokerageStatistics));
    dispatch(getBrokerageClosedSales(userId, getState().brokerageStatistics));
    dispatch(getBrokerageListPriceOverTime(userId, getState().brokerageStatistics));
    dispatch(getBrokerageNewListingsOverTime(userId, getState().brokerageStatistics));
};

export default slice;