import React, {
  createContext,
  useEffect,
  useReducer
} from 'react';
import type { FC, ReactNode } from 'react';
import jwtDecode from 'jwt-decode';
import type { User } from 'src/types/user';
import SplashScreen from 'src/components/SplashScreen';
import axiosmock from 'src/utils/axios_mock';
import axios from 'src/utils/axios';
import { apiConfig } from 'src/config';
import {logRocket} from 'src/config';
import LogRocket from 'logrocket';

interface Err {
  message: string,
  status: number
}

interface AuthState {
  isInitialised: boolean;
  isAuthenticated: boolean;
  user: User | null;
  err: Err | null;
}

interface AuthContextValue extends AuthState {
  method: 'JWT',
  login: (email: string, password: string) => Promise<void>;
  loginAsAgent: (agentId: string) => Promise<void>;
  logout: () => void;
  register: (email: string, name: string, password: string) => Promise<void>;
  update: (user: User, values: any, county: string, sid: string) => Promise<void>;
}

interface AuthProviderProps {
  children: ReactNode;
}

type InitialiseAction = {
  type: 'INITIALISE';
  payload: {
    isAuthenticated: boolean;
    user: User | null;
    err: Err | null;
  };
};

type LoginAction = {
  type: 'LOGIN';
  payload: {
    user: User;
    err: Err;
  };
};

type LogoutAction = {
  type: 'LOGOUT';
};

type RegisterAction = {
  type: 'REGISTER';
  payload: {
    user: User;
  };
};

type UpdateAction = {
  type: 'UPDATE';
  payload: {
    user: User;
  };
};

type Action =
  | InitialiseAction
  | LoginAction
  | LogoutAction
  | RegisterAction
  | UpdateAction;

const initialAuthState: AuthState = {
  isAuthenticated: false,
  isInitialised: false,
  user: null,
  err: null
};

const isValidToken = (accessToken: string): boolean => {
  if (!accessToken) {
    return false;
  }

  const decoded: any = jwtDecode(accessToken);
  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

const setSession = (accessToken: string | null): void => {
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);
    axiosmock.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    localStorage.removeItem('accessToken');
    delete axiosmock.defaults.headers.common.Authorization;
  }
};

const reducer = (state: AuthState, action: Action): AuthState => {
  switch (action.type) {
    case 'INITIALISE': {
      const { isAuthenticated, user, err } = action.payload;

      return {
        ...state,
        isAuthenticated,
        isInitialised: true,
        user,
        err
      };
    }
    case 'LOGIN': {
      const { user, err } = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        user,
        err
      };
    }
    case 'LOGOUT': {
      return {
        ...state,
        isAuthenticated: false,
        user: null
      };
    }
    case 'REGISTER': {
      const { user } = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        user
      };
    }
    case 'UPDATE': {
      const { user } = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        user
      };
    }
    default: {
      return { ...state };
    }
  }
};

const AuthContext = createContext<AuthContextValue>({
  ...initialAuthState,
  method: 'JWT',
  login: () => Promise.resolve(),
  loginAsAgent: () => Promise.resolve(),
  logout: () => { },
  register: () => Promise.resolve(),
  update: () => Promise.resolve()
});

export const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialAuthState);

  const login = async (email: string, password: string, token: string = null) => {
    //`//${apiConfig.api_prefix}/lead/${brokerId}/${leadId}?token=ec6d365f4b9b4ab942ec7ab0f328aa6a`
    const response = await axios.post<{ accessToken: string; user: User; err: any }>(`//${apiConfig.api_prefix}/account/login`, { email, password, token });
    const accessToken = response.data.user.accessToken;
    const user = response.data.user.user;

    if(user && user.name && user.email) {
      LogRocket.identify(logRocket.key, {
        name: user.name,
        email: user.email,
      });
    }

    const err = response.data.err;
    setSession(accessToken);
    dispatch({
      type: 'LOGIN',
      payload: {
        user,
        err
      }
    });
  };

  const loginAsAgent = async (agentId: string) => {
    const response = await axios.post<{ accessToken: string; user: User; err: any }>(`//${apiConfig.api_prefix}/account/loginAsAgent/${agentId}`);
    const accessToken = response.data.user.accessToken;
    const user = response.data.user.user;
    const err = response.data.err;
    setSession(accessToken);
    dispatch({
      type: 'LOGIN',
      payload: {
        user,
        err
      }
    });
  };

  const logout = () => {
    setSession(null);
    dispatch({ type: 'LOGOUT' });
  };

  const register = async (email: string, name: string, password: string) => {
    const response = await axios.post<{ accessToken: string; user: User }>('//${apiConfig.api_prefix}/account/register', {
      email,
      name,
      password
    });
    const { accessToken, user } = response.data;

    window.localStorage.setItem('accessToken', accessToken);

    dispatch({
      type: 'REGISTER',
      payload: {
        user
      }
    });
  };

  const update = async(user: User, values: any, county: string, sid: string) => {
    let userCopy = Object.assign({}, user)
    delete userCopy.avatar;
    const response = await axios.put(`//${apiConfig.api_prefix}/account/me`, {
        ...userCopy,
        ...values,
        ccode: county,
        sid: sid
    });

    dispatch({
      type: 'UPDATE',
      payload: { 
        user: {
          ...user,
          ...values,
        }
      }
    });
  };

  useEffect(() => {
    const initialise = async () => {
      try {
        const accessToken = window.localStorage.getItem('accessToken');

        if (accessToken && isValidToken(accessToken)) {
          setSession(accessToken);

          const response = await axios.get<{ user: User; err: Err }>(`//${apiConfig.api_prefix}/account/me`);
          const user = response.data.user.user;

          if(user && user.name && user.email) {
            LogRocket.identify(logRocket.key, {
              name: user.name,
              email: user.email,
            });
          }
          const err = response.data.err;
          

          // add an error check if the above request throws an error
          // Added because a user would see a blank login page if the token has expired
          if(err && err.status) {
            throw err;
          }

          dispatch({
            type: 'INITIALISE',
            payload: {
              isAuthenticated: true,
              user,
              err
            }
          });
        } else {
          dispatch({
            type: 'INITIALISE',
            payload: {
              isAuthenticated: false,
              user: null,
              err: null
            }
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INITIALISE',
          payload: {
            isAuthenticated: false,
            user: null,
            err: null
          }
        });
      }
    };

    initialise();
  }, []);

  if (!state.isInitialised) {
    return <SplashScreen />;
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'JWT',
        login,
        loginAsAgent,
        logout,
        register,
        update
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
