import React from 'react';
import type { FC } from 'react';
import useSettings from 'src/hooks/useSettings';
import {useLocation} from 'react-router-dom';


interface LogoProps {
  [key: string]: any;
}

const Logo: FC<LogoProps> = (props) => {  
  const { settings } = useSettings();
  const location = useLocation();
  
  if((location.pathname === "/" || location.pathname.includes('/pricing')) && settings.theme === "LANDBROKER"){ //if on light theme and the main page or pricing, return dark logo (only place with light header bar). Otherwise, return light.
    return (
    <img
      alt="Logo"
      // src="/static/logo.svg"
      src="/static/CoOpLogo_Large.png"
      // style={{maxHeight: "50px"}}
      {...props}
      style={{ width: 228 }}
    />)
  }
  else {
    return (
    <img
      alt="Logo"
      // src="/static/logo.svg"
      src="/static/LANDBROKERMLSWhite.svg"
      // style={{maxHeight: "50px"}}
      {...props}
    />)
    }
}

export default Logo;
