import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';
import type { AppThunk } from 'src/store'
import { apiConfig } from 'src/config';
import axios from 'src/utils/axios';
import type { Lead } from 'src/types/lead';

interface LeadState {
  query,
  data
};

const initialState: LeadState = {
  query: '',
  data: []
};

const slice = createSlice({
  name: 'lead',
  initialState,
  reducers: {
    getLeads(state: LeadState, action: PayloadAction<{leads: Lead[]}>){
      const newState = { data: []};
      newState.data = action.payload.leads;
      return Object.assign({}, state, newState);
    },
    setQuery(state: LeadState, action: PayloadAction<{query}>) {
      state.query = action.payload.query;
      return state;
    }
}});

export const reducer = slice.reducer;

export const getLeads = (state: LeadState): AppThunk => async (dispatch) => {
  try{
    let propResponse = await axios.get<{leads: Lead[]; }>(`//${apiConfig.api_prefix}/lead/`,{
      params: {
        query: state.query
      }
    });
    console.log(propResponse.data.leads);
    dispatch(slice.actions.getLeads({leads: propResponse.data.leads}));
  }
  catch(err){
    console.log(err);
  }
};

export const setQuery = (query: string): AppThunk => async (dispatch, getState) => {
  dispatch(slice.actions.setQuery({query}));
  dispatch(getLeads(getState().lead));
}

export default slice;
