import React from 'react';
import type { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  AppBar,
  Box,
  Button,
  Divider,
  Toolbar,
  Grid,
  Hidden,
  Typography,
  Link,
  makeStyles
} from '@material-ui/core';
import Logo from 'src/components/Logo';

interface TopBarProps {
  className?: string;
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default
  },
  toolbar: {
    height: 64
  },
  logo: {
    marginRight: theme.spacing(2),
    maxHeight: '50px',
    [theme.breakpoints.down('sm')]: {
      maxHeight: '40px'
    },
    [theme.breakpoints.down('xs')]: {
      maxHeight: '34px'
    }
  },
  link: {
    fontWeight: theme.typography.fontWeightMedium,
    '& + &': {
      marginLeft: theme.spacing(2)
    }
  },
  divider: {
    width: 1,
    height: 32,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2)
  }
}));

const TopBar: FC<TopBarProps> = ({ className, ...rest }) => {
  const classes = useStyles();

  return (
    <AppBar className={clsx(classes.root, className)} color="default" {...rest}>
      <Toolbar className={classes.toolbar}>
        {/* <Hidden smDown> */}
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <RouterLink to="/" style={{ maxWidth: '100%' }}>
              <Logo style={{ maxWidth: '100%' }} />
            </RouterLink>
          </Grid>
          <Grid xs={6} item alignItems={'center'}>
            {/* <Link
          className={classes.link}
          color="textSecondary"
          component={RouterLink}
          to="/app"
          underline="none"
          variant="body2"
        >
          Land Broker Co-op Dashboard
        </Link> */}
            <Grid
              container
              spacing={1}
              justify={'flex-end'}
              alignContent={'center'}
              alignItems={'center'}
              style={{ height: '100%' }}
            >
              <Hidden smDown>
                <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                  <Link
                    className={classes.link}
                    color="textSecondary"
                    component={RouterLink}
                    to="https://www.landbrokermls.com/about"
                    underline="none"
                    variant="body2"
                  >
                    About
                  </Link>
                  <Divider className={classes.divider} />
                </Grid>
              </Hidden>
              <Grid
                item
                xs={7}
                sm={'auto'}
                style={{ display: 'flex', justifyContent: 'flex-end' }}
              >
                <Button
                  color="secondary"
                  component="a"
                  href="/pricing"
                  variant="outlined"
                  size="small"
                >
                  Sign Up
                </Button>
              </Grid>
              {/* <span>&nbsp;&nbsp;&nbsp;</span> */}
              <Grid item xs={5} sm={'auto'}>
                <Button
                  color="primary"
                  component="a"
                  href="/login"
                  variant="contained"
                  size="small"
                >
                  Log In
                </Button>
              </Grid>
            </Grid>
          </Grid>
          {/* </Hidden> */}
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string
};

export default TopBar;
