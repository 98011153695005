import { combineReducers } from '@reduxjs/toolkit';
import { reducer as calendarReducer } from 'src/slices/calendar';
import { reducer as chatReducer } from 'src/slices/chat';
import { reducer as formReducer } from 'redux-form';
import { reducer as kanbanReducer } from 'src/slices/kanban';
import { reducer as mailReducer } from 'src/slices/mail';
import { reducer as notificationReducer } from 'src/slices/notification';
import { reducer as globalStatisticsReducer } from 'src/slices/statistics/global';
import { reducer as personalStatisticsReducer } from 'src/slices/statistics/personal';
import { reducer as brokerageStatisticsReducer } from 'src/slices/statistics/brokerage';
import { reducer as propertyReducer } from 'src/slices/property';
import { reducer as memberDetailsReducer } from 'src/slices/memberDetails';
import { reducer as needReducer } from 'src/slices/need';
import { reducer as leadReducer } from 'src/slices/lead';

const rootReducer = combineReducers({
  calendar: calendarReducer,
  chat: chatReducer,
  form: formReducer,
  kanban: kanbanReducer,
  mail: mailReducer,
  notifications: notificationReducer,
  globalStatistics: globalStatisticsReducer,
  personalStatistics: personalStatisticsReducer,
  brokerageStatistics: brokerageStatisticsReducer,
  property: propertyReducer,
  memberDetails: memberDetailsReducer,
  need: needReducer,
  lead: leadReducer,

});

export default rootReducer;
