const rules = {
    visitor: {
      static: [
        "view:dashboard",
        "read:account",
        "update:account",
        "read:property"
      ]
    },
    "member/broker": {
      static: [
        "view:dashboard",
        "read:account",
        "update:account",
        "read:property",
        "create:property", 
        "update:property",
        "delete:property",
        "create:lead",
        "read:lead",
        "update:lead",
        "delete:lead",
        "read:user", 
        "read:broker"
      ],
    },
    "member/appraiser": {
        static: [
          "view:dashboard",
          "read:account",
          "update:account",
          "read:property",
          "create:lead",
          "read:lead",
          "update:lead",
          "delete:lead",
          "read:user", 
          "read:broker"
        ]
    },
    owner: {
        static: [
          "view:dashboard",
          "read:account",
          "update:account",
          "read:property",
          "create:property", 
          "update:property",
          "delete:property",
          "create:lead",
          "read:lead",
          "update:lead",
          "delete:lead",
          "read:user",
          "create:broker",
          "read:broker",
          "update:broker",
          "delete:broker"
        ]
    },
    admin: {
      static: [
        "fake:user", //Fake being another user (Statistics pages)
        "view:dashboard",
        "read:account",
        "update:account",
        "read:property",
        "create:user",
        "read:user",
        "update:user",
        "delete:user",
        "create:account",
        "delete:account",
        "create:property",
        "update:property",
        "delete:property",
        "create:lead",
        "read:lead",
        "update:lead",
        "delete:lead",
        "accounts:admin" //general permission only available to admins
      ]
    }
  };
  
  export default rules;