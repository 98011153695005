import React from 'react';
import type { FC, ReactNode } from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuth from '../hooks/useAuth';

interface AuthGuardProps {
  children?: ReactNode;
}

const AuthGuard: FC<AuthGuardProps> = ({ children }) => {
  const { isAuthenticated, err } = useAuth();
  if (!isAuthenticated) {
    if(window.location.pathname != "/login"){
      sessionStorage.setItem("redirectAfterLogin", window.location.pathname); //dont set variable if loading login directly
    }
    return <Redirect to="/login" />;
  }
  if(err.status === 403){
    return <Redirect to="/404" /> //Replace with "your account isn't active yet" page
  }
  if(sessionStorage.getItem("redirectAfterLogin")){ //If they're authenticated and not recieving a 403 and were forced to login originally, redirect to original path.
    let redirectToMe = sessionStorage.getItem("redirectAfterLogin");
    sessionStorage.removeItem("redirectAfterLogin");
    return <Redirect to={redirectToMe} />
  }

  return ( //otherwise just load the app like normal.
    <>
      {children}
    </>
  );
};

AuthGuard.propTypes = {
  children: PropTypes.node
};

export default AuthGuard;
