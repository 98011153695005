/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import type { FC, ReactNode } from 'react';
import { useLocation, matchPath } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import type { Theme } from 'src/theme';
import axios from 'src/utils/axios';
import {
  Avatar,
  Box,
  Button,
  Divider,
  Dialog,
  Drawer,
  Hidden,
  Link,
  List,
  ListSubheader,
  Typography,
  makeStyles,
  Chip
} from '@material-ui/core';
import { useSnackbar } from 'notistack';

// Find these on https://feathericons.com/
import {
  Home as HomeIcon,
  Briefcase as BriefcaseIcon,
  Bell as NotificationsIcon,
  Map as MapIcon,
  Gift as GiftIcon,
  ShoppingCart as ShoppingCartIcon,
  BarChart as BarChartIcon,
  BarChart2 as BarChart2Icon,
  PieChart as PieChartIcon,
  Users as UsersIcon,
  Layers as LayersIcon,
  CheckCircle as CheckCircleIcon,
  UserCheck as UserCheckIcon,
  Bookmark as BookmarkIcon,
  Clipboard as ClipboardIcon,
  Search as SearchIcon,
  Target as TargetIcon,
  Flag as FlagIcon,
  Award as AwardIcon,
  Shield as ShieldIcon,
  Phone as PhoneIcon,
  TrendingUp as TrendingUpIcon,
  Rss as RssFeedIcon,
  Code as CodeIcon,
  Save as SaveIcon,
  Cpu as Cpu
} from 'react-feather';

import LogoSmall from 'src/components/LogoSmall';
import useAuth from 'src/hooks/useAuth';
import NavItem from './NavItem';
import Can from 'src/components/Can';
import AgentRequestDialog from 'src/components/AgentRequestDialog';

interface NavBarProps {
  onMobileClose: () => void;
  openMobile: boolean;
}

interface Item {
  href?: string;
  icon?: ReactNode;
  info?: ReactNode;
  items?: Item[];
  title: string;
  permission: string;
}

interface Section {
  items: Item[];
  subheader: string;
  permission: string;
}
const sections: Section[] = [
  {
    subheader: '',
    permission: 'view:dashboard',
    items: [
      {
        title: 'Home',
        icon: HomeIcon,
        href: '/app/landing',
        permission: 'view:dashboard'
      }
    ]
  },
  {
    subheader: 'Management',
    permission: 'create:property',
    items: [
      {
        title: 'Listings',
        icon: ClipboardIcon,
        href: '/app/management/properties',
        permission: 'update:property'
      },
      // {user.membership_type == 12 || user.membership_type == 13 && {
      //   title: 'Upgrade',
      //   icon: ClipboardIcon,
      //   href: '/app/management/update',
      //   permission: 'update:property'
      // }},
      // {
      //   title: 'Upgrade',
      //   icon: ClipboardIcon,
      //   href: '/app/management/update',
      //   permission: 'update:property'
      // },
      // {
      //   title: 'Register Buyers',
      //   icon: UsersIcon,
      //   href: '/app/management/customers',
      //   items: [
      //     {
      //       title: 'List Buyers',
      //       href: '/app/management/customers'
      //     },
      //     {
      //       title: 'View Buyers',
      //       href: '/app/management/customers/1'
      //     },
      //     {
      //       title: 'Edit Buyers',
      //       href: '/app/management/customers/1/edit'
      //     }
      //   ]
      // },
      {
        title: 'Manage Feeds',
        icon: RssFeedIcon,
        href: '/app/management/feeds',
        permission: 'read:feeds' // figure out correct scope to manage feed
      },
      {
        title: 'Quick Generate Feeds',
        icon: RssFeedIcon,
        href: '/app/management/quickGenerateFeeds',
        permission: 'read:feeds' // figure out correct scope to manage feed
      },
      {
        title: 'Wants & Needs',
        icon: BookmarkIcon,
        href: '/app/management/needs',
        permission: 'read:lead'
        // items: [
        //   {
        //     title: 'List Wants and Needs',
        //     // href: '#'
        //     href: '/app/management/needs',
        //     permission: 'read:lead',
        //     info: () => (
        //       <Chip
        //         color="secondary"
        //         size="small"
        //         label="New"
        //       />
        //     ),
        //   },
        //   {
        //     title: 'Create Wants and Needs',
        //     // href: '#'
        //     href: '/app/management/needs/create',
        //     permission: 'create:lead',
        //     info: () => (
        //       <Chip
        //         color="secondary"
        //         size="small"
        //         label="New"
        //       />
        //     ),
        //   }
        // ]
      },
      {
        title: 'Property Alerts',
        icon: NotificationsIcon,
        href: '/app/management/alerts',
        permission: 'read:property-alerts'
      }
      // {
      //   title: 'Products',
      //   icon: ShoppingCartIcon,
      //   href: '/app/management/products',
      //   items: [
      //     {
      //       title: 'List Products',
      //       href: '/app/management/products'
      //     },
      //     {
      //       title: 'Create Product',
      //       href: '/app/management/products/create'
      //     }
      //   ]
      // },
      // {
      //   title: 'Orders',
      //   icon: FolderIcon,
      //   href: '/app/management/orders',
      //   items: [
      //     {
      //       title: 'List Orders',
      //       href: '/app/management/orders'
      //     },
      //     {
      //       title: 'View Order',
      //       href: '/app/management/orders/1'
      //     }
      //   ]
      // },
      // {
      //   title: 'Invoices',
      //   icon: ReceiptIcon,
      //   href: '/app/management/invoices',
      //   items: [
      //     {
      //       title: 'List Invoices',
      //       href: '/app/management/invoices'
      //     },
      //     {
      //       title: 'View Invoice',
      //       href: '/app/management/invoices/1'
      //     }
      //   ]
      // }
    ]
  },
  {
    subheader: 'Sales',
    permission: 'read:lead',
    items: [
      {
        title: 'Leads',
        icon: TargetIcon,
        href: '/app/sales/leads',
        permission: 'read:lead'
      }
      // {
      //   title: 'Placeholder',
      //   icon: GiftIcon,
      //   href: '/app/features/placeholder',
      //   permission: 'create:lead'
      // },
    ]
  },
  {
    subheader: 'Private Dashboards',
    permission: 'read:property-stats',
    items: [
      {
        title: 'Property Statistics',
        icon: BarChart2Icon,
        href: '/app/reports/property-statistics',
        permission: 'read:property-stats'
      },
      {
        title: 'Personal Dashboard',
        icon: PieChartIcon,
        href: '/app/reports/personal-statistics',
        permission: 'read:property-stats'
      },
      {
        title: 'Brokerage Dashboard',
        icon: LayersIcon,
        href: '/app/reports/brokerage-statistics',
        permission: 'read:property-stats'
      }
    ]
  },
  {
    subheader: 'Research',
    permission: 'read:property-stats',
    items: [
      // {
      //   title: 'Global Dashboard',
      //   icon: BarChartIcon,
      //   href: '/app/reports/global-statistics',
      //   permission: 'read:property-stats'
      // },
      {
        title: 'Property Search',
        icon: SearchIcon,
        href: '/app/research/properties',
        permission: 'read:property-search'
      },
      {
        title: 'Saved Listings',
        icon: SaveIcon,
        href: '/app/research/savedListings',
        permission: 'read:saved-property'
      },
      {
        title: 'Co-op Member Search',
        icon: UsersIcon,
        href: '/app/research/members',
        permission: 'read:user'
      },
      {
        title: 'Wants & Needs Search',
        icon: BookmarkIcon,
        href: '/app/research/needs',
        permission: 'read:lead'
      }
      // {
      //   title: 'Map Search',
      //   icon: MapIcon,
      //   href: '/app/research/properties/map',
      //   permission: 'read:property',
      //   info: () => (
      //     <Chip
      //       color="default"
      //       size="small"
      //       label="soon"
      //     />
      //   )
      // },
      // {
      //   title: 'Comp Data',
      //   icon: TrendingUpIcon,
      //   href: '/app/research/compdata',
      //   permission: 'read:lead',
      //   info: () => (
      //     <Chip
      //       color="default"
      //       size="small"
      //       label="soon"
      //     />
      //   )
      // },
    ]
  },
  {
    subheader: 'Resources',
    permission: 'view:dashboard',
    items: [
      {
        title: 'Ambassadors',
        icon: FlagIcon,
        href: '/app/resources/ambassadors',
        permission: 'view:dashboard'
      },
      // {
      //   title: 'RealX',
      //   icon: AwardIcon,
      //   href: '/app/resources/realx',
      //   permission: 'view:dashboard'
      // },
      {
        title: 'Health Insurance',
        icon: ShieldIcon,
        href: '/app/resources/insurance',
        permission: 'view:dashboard'
      },
      {
        title: 'Member Benefits',
        icon: GiftIcon,
        href: '/app/resources/benefits',
        permission: 'view:dashboard'
      },
      {
        title: 'Contact Co-op',
        icon: PhoneIcon,
        href: '/app/resources/contact',
        permission: 'view:dashboard'
      },
      {
        title: 'Custom MLS Search',
        icon: CodeIcon,
        href: '/app/resources/customsearch',
        permission: 'view:dashboard'
      },
      {
        title: 'AI Chat',
        icon: Cpu,
        href: '/app/resources/aichat',
        permission: 'view:dashboard'
      }
      // {
      //   title: 'Placeholder',
      //   icon: GiftIcon,
      //   href: '/app/features/placeholder',
      //   permission: 'create:lead'
      // },
    ]
  }
  // {
  //   subheader: 'Extras',
  //   items: [
  //     {
  //       title: 'Projects Platform',
  //       href: '/app/projects',
  //       icon: BriefcaseIcon,
  //       items: [
  //         {
  //           title: 'Overview',
  //           href: '/app/projects/overview'
  //         },
  //         {
  //           title: 'Browse Projects',
  //           href: '/app/projects/browse'
  //         },
  //         {
  //           title: 'Create Project',
  //           href: '/app/projects/create'
  //         },
  //         {
  //           title: 'View Project',
  //           href: '/app/projects/1'
  //         }
  //       ]
  //     },
  //     // {
  //     //   title: 'Social Platform',
  //     //   href: '/app/social',
  //     //   icon: ShareIcon,
  //     //   items: [
  //     //     {
  //     //       title: 'Profile',
  //     //       href: '/app/social/profile'
  //     //     },
  //     //     {
  //     //       title: 'Feed',
  //     //       href: '/app/social/feed'
  //     //     }
  //     //   ]
  //     // },
  //     // {
  //     //   title: 'Kanban',
  //     //   href: '/app/kanban',
  //     //   icon: TrelloIcon
  //     // },
  //     {
  //       title: 'Mail',
  //       href: '/app/mail',
  //       icon: MailIcon
  //     },
  //     {
  //       title: 'Chat',
  //       href: '/app/chat',
  //       icon: MessageCircleIcon,
  //       info: () => (
  //         <Chip
  //           color="secondary"
  //           size="small"
  //           label="Updated"
  //         />
  //       )
  //     },
  //     {
  //       title: 'Calendar',
  //       href: '/app/calendar',
  //       icon: CalendarIcon,
  //       info: () => (
  //         <Chip
  //           color="secondary"
  //           size="small"
  //           label="Updated"
  //         />
  //       )
  //     }
  //   ]
  // },
  // {
  //   subheader: 'Auth Demo',
  //   items: [
  //     {
  //       title: 'Login',
  //       href: '/login-unprotected',
  //       icon: LockIcon
  //     },
  //     {
  //       title: 'Register',
  //       href: '/register-unprotected',
  //       icon: UserPlusIcon
  //     }
  //   ]
  // },
  // {
  //   subheader: 'Pages',
  //   items: [
  //     {
  //       title: 'Account',
  //       href: '/app/account',
  //       icon: UserIcon
  //     },
  //     {
  //       title: 'Error',
  //       href: '/404',
  //       icon: AlertCircleIcon
  //     },
  //     {
  //       title: 'Pricing',
  //       href: '/pricing',
  //       icon: DollarSignIcon
  //     }
  //   ]
  // },
  // {
  //   subheader: 'Extra',
  //   items: [
  //     {
  //       title: 'Charts',
  //       href: '/app/extra/charts',
  //       icon: BarChartIcon,
  //       items: [
  //         {
  //           title: 'Apex Charts',
  //           href: '/app/extra/charts/apex'
  //         }
  //       ]
  //     },
  //     {
  //       title: 'Forms',
  //       href: '/app/extra/forms',
  //       icon: EditIcon,
  //       items: [
  //         {
  //           title: 'Formik',
  //           href: '/app/extra/forms/formik'
  //         },
  //         {
  //           title: 'Redux Forms',
  //           href: '/app/extra/forms/redux'
  //         },
  //       ]
  //     },
  //     {
  //       title: 'Editors',
  //       href: '/app/extra/editors',
  //       icon: LayoutIcon,
  //       items: [
  //         {
  //           title: 'DraftJS Editor',
  //           href: '/app/extra/editors/draft-js'
  //         },
  //         {
  //           title: 'Quill Editor',
  //           href: '/app/extra/editors/quill'
  //         }
  //       ]
  //     }
  //   ]
  // }
];

function renderNavItems(
  user,
  {
    items,
    pathname,
    depth = 0,
    permission
  }: {
    items: Item[];
    pathname: string;
    depth?: number;
    permission: string;
  }
) {
  return (
    <Can
      role={user.scope}
      perform={permission}
      yes={() => (
        <List disablePadding>
          {items.reduce(
            (acc, item) =>
              reduceChildRoutes(user, { acc, item, pathname, depth }),
            []
          )}
        </List>
      )}
      no={() => <></>}
    />
  );
}

function reduceChildRoutes(
  user,
  {
    acc,
    pathname,
    item,
    depth
  }: {
    acc: any[];
    pathname: string;
    item: Item;
    depth: number;
  }
) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false
    });

    acc.push(
      <Can
        role={user.scope}
        perform={item.permission}
        yes={() => (
          <NavItem
            depth={depth}
            icon={item.icon}
            info={item.info}
            key={key}
            open={Boolean(open)}
            title={item.title}
          >
            {renderNavItems(user, {
              depth: depth + 1,
              pathname,
              items: item.items,
              permission: item.permission
            })}
          </NavItem>
        )}
        no={() => <></>}
      />
    );
  } else {
    acc.push(
      <Can
        role={user.scope}
        perform={item.permission}
        key={key}
        yes={() => (
          <NavItem
            depth={depth}
            href={item.href}
            icon={item.icon}
            info={item.info}
            key={key}
            title={item.title}
          />
        )}
        no={() => <></>}
      />
    );
  }

  return acc;
}

const useStyles = makeStyles((theme: Theme) => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  },
  buttonA: {
    textDecoration: 'none',
    color: theme.palette.secondary.main
  }
}));

const NavBar: FC<NavBarProps> = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();
  const { user } = useAuth();

  useEffect(() => {
    if (user.membership_type == '12' || user.membership_type == '13') {
      sections[1].items.push({
        title: 'Upgrade Account',
        icon: ClipboardIcon,
        href: '/app/management/update',
        permission: 'update:property'
      });
    }
  }, []);

  const [meta, setMeta] = React.useState({ version: '' });
  const [openDialog, setOpenDialog] = React.useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const handleClose = () => {
    setOpenDialog(false);
  };

  const openNotification = () => {
    setOpenDialog(true);
  };

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  useEffect(() => {
    if (user.invitedAsAgentByUserId && !user.ListOfficeId) {
      const notificationSnackbar = enqueueSnackbar(
        'You have an invitation to view.',
        {
          variant: 'info',
          action: (
            <Button
              onClick={(e) => {
                openNotification();
                closeSnackbar(notificationSnackbar);
              }}
              style={{ color: 'white', borderColor: 'white' }}
              variant="outlined"
            >
              View
            </Button>
          ),
          persist: true
        }
      );
    }

    const fetchMetaData = async () => {
      let meta = await axios('/meta.json');
      if (meta && meta.data && meta.data.version) {
        console.log(JSON.stringify(meta.data));
        setMeta(Object.assign({}, meta.data));
      }
    };

    fetchMetaData();
  }, []);

  const checkSectionScopes = (section) => {
    // Use this to determine if the section subheader needs to show

    return user.scope.includes(section.permission);
  };

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Hidden lgUp>
          <Box p={2} display="flex" justifyContent="center">
            <RouterLink to="/">
              <LogoSmall />
            </RouterLink>
          </Box>
        </Hidden>
        <Box p={2}>
          <Box display="flex" justifyContent="center">
            <RouterLink to="/app/account">
              <Avatar
                alt="User"
                className={classes.avatar}
                src={
                  user.mempicSource && user.mempicSource === 'new dash'
                    ? user.avatar
                    : `https://landbrokermls.com/search/mem_brokerlink.php?link=${user.avatar}`
                }
              />
            </RouterLink>
          </Box>
          <Box mt={2} textAlign="center">
            <Link
              component={RouterLink}
              to="/app/account"
              variant="h5"
              color="textPrimary"
              underline="none"
            >
              {user.name}
            </Link>
            <Typography variant="body2" color="textSecondary">
              Co-op Member
            </Typography>
            <Typography style={{ fontSize: 12 }} color="textSecondary">
              App Version: {meta && meta.version && `v${meta.version}`}
            </Typography>
          </Box>
        </Box>
        <Divider />
        <Box p={2}>
          {sections.map((section) => (
            <List
              key={section.subheader}
              subheader={
                checkSectionScopes(section) ? (
                  <ListSubheader disableGutters disableSticky>
                    {section.subheader}
                  </ListSubheader>
                ) : (
                  <></>
                )
              }
            >
              {renderNavItems(user, {
                items: section.items,
                pathname: location.pathname,
                permission: section.permission
              })}
            </List>
          ))}
        </Box>
        <Divider />
        <Box p={2}>
          <Box p={2} borderRadius="borderRadius" bgcolor="background.dark">
            <Typography variant="h6" color="textPrimary">
              Questions / Need Help?
            </Typography>
            {/* <Typography
              variant="subtitle1"
              color="secondary"
            >
              <a href="https://www.landbrokermls.com/contact.php" target="_blank" className={classes.buttonA}>Contact Support</a>
            </Typography> */}
            <Link
              variant="subtitle1"
              color="secondary"
              component={RouterLink}
              to="/app/resources/contact"
            >
              Contact Support
            </Link>
          </Box>
        </Box>
      </PerfectScrollbar>
      <AgentRequestDialog open={openDialog} onClose={handleClose} user={user} />
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default NavBar;
