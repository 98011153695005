import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';
import type { AppThunk } from 'src/store'
import { apiConfig } from 'src/config';
import axios from 'src/utils/axios';
import type { Property } from 'src/types/property';
import type { Search } from 'src/types/search';
import type { Pagination } from 'src/types/pagination';
import numeral from 'numeral';
import states from 'src/data/states.json';

interface PropertyState {
  management: {
    filters: {
      query,
      mode,
      ptype,
      minPrice,
      maxPrice,
      minAcre,
      maxAcre,
      listDateMin,
      listDateMax,
      Features,
      state,
      county,
      listingStatus,
      category
    },
    data,
    count,
    pagination: {
      limit: Number, // This will be used to limit the data returned as part of the properties request
      offset: Number, // This will be used to offset the results returned from the backend
    },
   },
  search: {
   filters: {
    query,
    mode,
    ptype,
    minPrice,
    maxPrice,
    minAcre,
    maxAcre,
    listDateMin,
    listDateMax,
    Features,
    state,
    county,
    listingStatus,
    memberOwner,
    category
   },
   data,
   count
   pagination: {
     limit: Number, // This will be used to limit the data returned as part of the properties request
     offset: Number, // This will be used to offset the results returned from the backend
   },
  },
  pagination: {
    limit: Number, // This will be used to limit the data returned as part of the properties request
    offset: Number, // This will be used to offset the results returned from the backend
  },
  order: string,
  saved: {
    searches,
    count,
  },
  loading
};

const initialState: PropertyState = {
 management: {
   filters: {
      query: "",
      mode: null,
      ptype: [],
      minPrice: null,
      maxPrice: null,
      minAcre: null,
      maxAcre: null,
      listDateMin: null,
      listDateMax: null,
      Features: [],
      state: null,
      county: null,
      listingStatus: null,
      category: "All"
    },
    data: [],
    count: 0,
    pagination: {
      limit: 10,
      offset: 0,
    },
  },
  search: {
    filters: {
     query: "",
     mode: null,
     ptype: [],
     minPrice: null,
     maxPrice: null,
     minAcre: null,
     maxAcre: null,
     listDateMin: null,
     listDateMax: null,
     Features: [],
     state: null,
     county: null,
     listingStatus: null,
     memberOwner: null,
     category: "All"
   },
    data: [],
    count: 0,
    pagination: {
      limit: 10,
      offset: 0,
    },
  },
  pagination: {
    limit: 10, // This will be used to limit the data returned as part of the properties request
    offset: 0, // This will be used to offset the results returned from the backend
  },
  order: '',
  saved: {
    searches: [],
    count: 0,
  },
  loading: false
};

const slice = createSlice({
  name: 'property',
  initialState,
  reducers: {
    getManagementProperties(state: PropertyState, action: PayloadAction<{properties: Property[], count?: number}>){
      action.payload.properties.map(e => {
        e.formattedPrice = `${numeral(e.lprice).format(`$0,0`)}`;
        let locationArray = [];
        if(e.address1) locationArray.push(e.address1);
        if(e.city) locationArray.push(e.city);
        if(e.sid){
          //county isn't a normal part of an address, so just use the state. Also much faster because we don't have to query the DB for states.
          locationArray.push(states[e.sid].iso2);
        } 
        e.formattedLocation = locationArray.join(', ') + (e.zip? ` ${e.zip}` : ""); //add zipcode after state with no comma, if it exists. 
      })
      const newState = { 
        management: {
          ...state.management,
          // data: [], 
          // count: state.management.count || 0,
          filters: {
            ...state.management.filters
          }
        } 
      }
      newState.management.data = action.payload.properties;

      if("count" in action.payload) {
        newState.management.count = action.payload.count;
      } 

      return Object.assign({}, state, newState);
    },
    getPropAlerts(state: PropertyState, action: PayloadAction<{searches: Search[], count?: number}>){
      action.payload.searches.map(e => {
        if(e.minPrice && e.minPrice != -1)
          {
          if(e.maxPrice != 2147483647 && e.maxPrice != -1){
            e.formattedPrice = `${numeral(e.minPrice).format(`$0,0`)} to ${numeral(e.maxPrice).format(`$0,0`)}`;
          } else {
              e.formattedPrice = `> ${numeral(e.minPrice).format(`$0,0`)}`;
            }
          }
        else if(e.maxPrice != 2147483647 && e.maxPrice != -1){
          e.formattedPrice = `< ${numeral(e.maxPrice).format(`$0,0`)}`;
        }
        else {
          e.formattedPrice = "Any Price"
        }

        if(e.minAcre && e.minAcre != -1)
          {
          if(e.maxAcre != 2147483647 && e.maxAcre != -1){
            e.formattedAcreage = `${e.minAcre} to ${e.maxAcre}`;
          } else {
              e.formattedAcreage = `> ${e.minAcre}`;
            }
          }
        else if(e.maxAcre != 2147483647 && e.maxAcre != -1){
          e.formattedAcreage = `< ${e.maxAcre}`;
        }
        else {
          e.formattedAcreage = "Any Acreage"
        }

        if(e.state && e.state != ""){
          if(e.cname && e.cname != ""){
            e.formattedLocation = `${e.cname}, ${e.stateIso2}` //no supplied address
          }
          else e.formattedLocation = `${e.state}`;
        }

        if(e.enabled == '0'){
          e.enabled = "False"
        } else e.enabled = 'True';
      })
      state.saved.searches = action.payload.searches;
      if (action.payload.count) {
        state.saved.count = action.payload.count;
      }
      return state;
    },
    getSearchProperties(state: PropertyState, action: PayloadAction<{properties: Property[], count?: number}>){
      action.payload.properties.map(e => {
        e.formattedPrice = `${numeral(e.lprice).format(`$0,0`)}`;
        let locationArray = [];
        if(e.address1) locationArray.push(e.address1);
        if(e.city) locationArray.push(e.city);
        if(e.sid){
          //county isn't a normal part of an address, so just use the state. Also much faster because we don't have to query the DB for states.
          locationArray.push(states[e.sid].iso2);
        } 
        e.formattedLocation = locationArray.join(', ') + (e.zip? ` ${e.zip}` : ""); //add zipcode after state with no comma, if it exists. 
      })
      const newState = { 
        search: {
          // data: [], 
          // count: state.search.count || 0,
          ...state.search,
          filters: {
            ...state.search.filters
          },
          pagination: {
            ...state.search.pagination
          }
        } 
      }
      newState.search.data = action.payload.properties;

      // we are adding this here because we dont want to get count everytime as this affects performance
      // we'll get count when offset is 0
      // if the count is not in the payload then we dont want to reset it
      if("count" in action.payload) {
        newState.search.count = action.payload.count;
      } 

      return Object.assign({}, state, newState);
    },
    setManagementFilters(state: PropertyState, action: PayloadAction<{
      query,
     mode,
     ptype,
     minPrice,
     maxPrice,
     minAcre,
     maxAcre,
     listDateMin,
     listDateMax,
     Features,
     state,
     county,
     listingStatus,
     category
    }>) 
      {
      let data = action.payload;
      state.management.filters = {
        ...state.management.filters,
        ...data
      };
    },
    setManagementQuery(state: PropertyState, action: PayloadAction<{query}>) {
      state.management.filters = {
        ...state.management.filters,
        query: action.payload.query
      };
      return state;
    },
    setManagementCategory(state: PropertyState, action: PayloadAction<{category}>) {
      state.management.filters = {
        ...state.management.filters,
        category: action.payload.category
      };
      return state;
    },
    setSearchFilters(state: PropertyState, action: PayloadAction<{
     query,
     mode,
     ptype,
     minPrice,
     maxPrice,
     minAcre,
     maxAcre,
     listDateMin,
     listDateMax,
     Features,
     state,
     county,
     listingStatus,
     memberOwner,
     category,
     id?
    }>) 
      {
      let data = action.payload;
      state.search.filters = {
        ...state.search.filters,
        ...data
      };
      return state;
    },
    setSearchQuery(state: PropertyState, action: PayloadAction<{query}>) {
      state.search.filters = {
        ...state.search.filters,
        query: action.payload.query,
      };
      return state;
    },
    setSearchCategory(state: PropertyState, action: PayloadAction<{category}>) {
      state.search.filters = {
        ...state.search.filters,
        category: action.payload.category,
      };
      return state;
    },
    // this is for alerts
    setPaginationOptions(state: PropertyState, action: PayloadAction<Pagination>) {
      state.pagination = {
        ...action.payload
      }
      return state;
    },    
    setSearchPaginationOptions(state: PropertyState, action: PayloadAction<Pagination>) {
      state.search.pagination = {
        ...action.payload
      }
      return state;
    },    
    setManagementPaginationOptions(state: PropertyState, action: PayloadAction<Pagination>) {
      state.management.pagination = {
        ...action.payload
      }
      return state;
    },    
    setLoadingState(state: PropertyState, action: PayloadAction<boolean>) {
      state.loading = action.payload;
      return state;
    },    
    setOrderState(state: PropertyState, action: PayloadAction<string>) {
      state.order = action.payload;
      return state;
    }, 
}});

export const reducer = slice.reducer;

export const getProperties = (state: PropertyState, mode: string): AppThunk => async (dispatch, getState) => {
  try{

    dispatch(slice.actions.setLoadingState(true));
    let dashboardSettings = await axios.get<{new_search: Boolean; }>(`//${apiConfig.api_prefix}/dashboard/settings/search`);
    let experimentalSearch = false;
    if(dashboardSettings && dashboardSettings.data && dashboardSettings.data.new_search) {
      experimentalSearch = true;
    }

    if(mode == "management"){
      let url = experimentalSearch ? `//${apiConfig.api_prefix}/property/search` : `//${apiConfig.api_prefix}/property`
      let propResponse = await axios.get<{properties: Property[]; count?: number }>(url,{
        params: {
          query: state.management.filters && state.management.filters.query,
          ...state.management.filters,
          ...state.management.pagination,
          mode: "management",
          order: state.order,
          state: state.management.filters && state.management.filters.state,
          ptype: state.management.filters && state.management.filters["Property Types"],
          minPrice: state.management.filters && state.management.filters["Min Price"],
          maxPrice: state.management.filters &&  state.management.filters["Max Price"],
          minAcre: state.management.filters &&  state.management.filters["Min Acreage"],
          maxAcre: state.management.filters && state.management.filters["Max Acreage"],
          listDateMin: state.management.filters && state.management.filters["List Date Min"],
          listDateMax: state.management.filters && state.management.filters["List Date Max"],
          category: state.management.filters && state.management.filters.category
        }
      });
      dispatch(slice.actions.getManagementProperties({properties: propResponse.data.properties, count: propResponse.data.count || state.management.count}));
    }
    if(mode == "search"){
      let url = experimentalSearch ? `//${apiConfig.api_prefix}/property/search` : `//${apiConfig.api_prefix}/property`
      let propResponse = await axios.get<{properties: Property[]; count?: number }>(url,{
          params: {
          query: state.search.filters && state.search.filters.query,
          ...state.search.filters,
          ...state.search.pagination, // Add pagination objects
          mode: "search",
          order: state.order,
          state: state.search.filters && state.search.filters.state,
          ptype: state.search.filters && state.search.filters["Property Types"],
          minPrice: state.search.filters && state.search.filters["Min Price"],
          maxPrice: state.search.filters && state.search.filters["Max Price"],
          minAcre: state.search.filters && state.search.filters["Min Acreage"],
          maxAcre: state.search.filters && state.search.filters["Max Acreage"],
          listDateMin: state.search.filters && state.search.filters["List Date Min"],
          listDateMax: state.search.filters && state.search.filters["List Date Max"],
          memberOwner: state.search.filters && state.search.filters.memberOwner,
          category: state.search.filters && state.search.filters.category
        }
      });

      
      dispatch(slice.actions.getSearchProperties({
        properties: propResponse.data.properties, 
        // if the count object is present in response get it from there, else get it from saved state
        count: propResponse.data.count || state.search.count
      }));
    }
  }
  catch(err){
    console.log(err);
  }
  dispatch(slice.actions.setLoadingState(false));
  
};

export const getPropAlerts = (state: PropertyState, id: string): AppThunk => async (dispatch) => {
  try{
    let alertResponse = await axios.get<{searches: Search[]; count?: Number }>(`//${apiConfig.api_prefix}/propAlert/searches/${id}`, {
      params: {
        ...state.pagination
      }
    });
    dispatch(slice.actions.getPropAlerts({
      searches: alertResponse.data.searches,
      count: alertResponse.data.count || state.saved.count
    }));
  }
  catch(err){
    console.log(err);
  }
};

export const setSearchFilters = (filters: { 
  query,
  mode,
  ptype,
  minPrice,
  maxPrice,
  minAcre,
  maxAcre,
  listDateMin,
  listDateMax,
  Features,
  state,
  county,
  listingStatus,
  memberOwner,
  category,
  id?
  }): AppThunk => async (dispatch, getState) => {
  dispatch(slice.actions.setSearchFilters(filters));
  dispatch(setPaginationOptionsWithoutRequest("search"));
  dispatch(getProperties(getState().property, "search"));
  }

export const setManagementFilters = (filters: { 
  query,
  mode,
  ptype,
  minPrice,
  maxPrice,
  minAcre,
  maxAcre,
  listDateMin,
  listDateMax,
  Features,
  state,
  county,
  listingStatus,
  category}): AppThunk => async (dispatch, getState) => {
  dispatch(slice.actions.setManagementFilters(filters));
  dispatch(getProperties(getState().property, "management")); //Use getState to send newly update state with timeRange
}

export const setSearchQuery = (query: string): AppThunk => async (dispatch, getState) => {
  dispatch(slice.actions.setSearchQuery({query}));
  dispatch(setPaginationOptionsWithoutRequest("search"));
  dispatch(getProperties(getState().property, "search"));
}

export const setSearchCategory = (category: string): AppThunk => async (dispatch, getState) => {
  dispatch(slice.actions.setSearchCategory({category}));
  dispatch(setPaginationOptionsWithoutRequest("search"));
  dispatch(getProperties(getState().property, "search"));
}

// on search we have to reset the offset to 0 because we need to include count of items that is valid with the search
export const setPaginationOptionsWithoutRequest = (mode: string): AppThunk => async(dispatch, getState) => {
  if(mode === 'management') dispatch(slice.actions.setManagementPaginationOptions({limit: getState().property.management.pagination.limit, offset: 0})); 
  else dispatch(slice.actions.setSearchPaginationOptions({limit: getState().property.search.pagination.limit, offset: 0})); 
  // update this to use management mode
}
export const setPaginationOptions = (limit: Number, offset: Number, mode: string): AppThunk => async(dispatch, getState) => {
  if(mode === 'management') dispatch(slice.actions.setManagementPaginationOptions({limit, offset})); 
  else dispatch(slice.actions.setSearchPaginationOptions({limit, offset})); 
  // update this to use management mode
  dispatch(getProperties(getState().property, mode));
}
export const setPaginationOptionsForPropertyAlerts = (limit: Number, offset: Number, userId: string): AppThunk => async(dispatch, getState) => {
  dispatch(slice.actions.setPaginationOptions({limit, offset}));
  dispatch(getPropAlerts(getState().property, userId));
}

export const setSortOrder = (order: string, mode: string): AppThunk => async (dispatch, getState) => {
  dispatch(slice.actions.setOrderState(order));
  dispatch(getProperties(getState().property, mode));
}

export const setManagementQuery = (query: string): AppThunk => async (dispatch, getState) => {
  dispatch(slice.actions.setManagementQuery({query}));
  dispatch(setPaginationOptionsWithoutRequest("management"));
  dispatch(getProperties(getState().property, "management"));
}

export const setManagementCategory = (category: string): AppThunk => async (dispatch, getState) => {
  dispatch(slice.actions.setManagementCategory({category}));
  dispatch(setPaginationOptionsWithoutRequest("management"));
  dispatch(getProperties(getState().property, "management"));
}

export default slice;
