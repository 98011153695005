export const APP_VERSION = '0.1.0-alpha';

export const ENABLE_REDUX_DEV_TOOLS = true;

export const THEMES = {
  // LIGHT: 'LIGHT',
  DARK: 'DARK',
  LANDBROKER: 'LANDBROKER',
  // UNICORN: 'UNICORN'
};
