export const auth0Config = {
  client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
  domain: process.env.REACT_APP_AUTH0_DOMAIN
};

export const apiConfig = {
  api_prefix:
    process.env.REACT_APP_API_URL ||
    'api.property.eastwood.docker.localhost:8001'
};

export const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET
};

export const sentryConfig = {
  enabled: process.env.REACT_APP_SENTRY_ENABLED || false, // default to false
  dsn:
    process.env.REACT_APP_SENTRY_DSN ||
    'https://2de1e7dadf1a4f949db6fa9b6783ad8c@o468631.ingest.sentry.io/5497727',
  environment: process.env.REACT_APP_SENTRY_ENVIRONMENT || 'development',
  release: process.env.REACT_APP_SENTRY_RELEASE || 'v0.0.0-unknown'
};

export const mapConfig = {
  addressApiKey:
    process.env.REACT_APP_GOOGLE_ADDRESS_API_KEY ||
    `AIzaSyD-pTGpSHWjdoi79ZKafJDe3GHWRZ5ieeg`
};

export const logRocket = {
  key: process.env.REACT_APP_LOGROCKET || 'de7rem/lbc-dev'
};

export const paymentConfig = {
  stripeKey:
    process.env.REACT_APP_STRIPE_KEY ||
    'pk_test_51I5yJrC1UyXh4erh6qz1b5LKXJYkCK2JMT9yjMT4Eea0AzehbtFM51XWbhzRscQKNeHOiBmAPhlNYlDy1AVOA7fG00Z8bU7JWr'
};

// export const defaultUrl = 'http://localhost:3001';
export const defaultUrl = 'https://app.landbrokermls.com';

export const OpenAIAPIKey =
  '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';
