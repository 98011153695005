import React, {useRef, useState} from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import * as Yup from 'yup';
import axios from 'src/utils/axios';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  FormHelperText,
  Grid,
  TextField,
  makeStyles,
  Typography
} from '@material-ui/core';
import wait from 'src/utils/wait';
import { apiConfig } from 'src/config';
import useAuth from 'src/hooks/useAuth';

interface SecurityProps {
  className?: string;
  closePopup?: any;
  user?: any;
}
const useStyles = makeStyles(() => ({
  root: {}
}));

const Security: FC<SecurityProps> = ({ className, closePopup, user, ...rest }) => {
  const classes = useStyles();
  const [isOpen, setOpen] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();
  const { logout } = useAuth();
  const handleClose = (): void => {
    setOpen(false);
  };

  const handleLogout = async (): Promise<void> => {
    try {
      handleClose();
      await logout();
      // history.push('/');
    } catch (err) {
      console.error(err);
      enqueueSnackbar('Unable to logout', {
        variant: 'error'
      });
    }
  };
  return (
    <Formik
      initialValues={{
        password: '',
        passwordConfirm: '',
        submit: null
      }}
      validationSchema={Yup.object().shape({
        password: Yup.string()
          .min(7, 'Must be at least 7 characters')
          .max(255)
          .required('Required'),
        passwordConfirm: Yup.string()
          .oneOf([Yup.ref('password'), null], 'Passwords must match')
          .required('Required')
      })}
      onSubmit={async (values, {
        resetForm,
        setErrors,
        setStatus,
        setSubmitting
      }) => {
        try {
            const response = await axios.put(`//${apiConfig.api_prefix}/account/me/resetPassword`, {
                ...values
            });
          await wait(500);
          resetForm();
          setStatus({ success: true });
          setSubmitting(false);
          enqueueSnackbar('Password updated.', {
            variant: 'success'
          });
          console.log("Close: ", closePopup);
          if(closePopup){
            closePopup(values.password);
          }
        } catch (err) {
          console.error(err);
          setStatus({ success: false });
          setErrors({ submit: err.message });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values
      }) => (
        <form onSubmit={handleSubmit}>
          <Card
            className={clsx(classes.root, className)}
            {...rest}
          >
            <CardHeader title="Change Password" />
            <Divider />
            <CardContent>
              <Grid
                container
                spacing={3}
              >
                <Grid
                  item
                  xs={12}
                >
                  {closePopup && user &&
                  <Typography
                    variant="h5"
                    color="textPrimary"
                  >
                    Hi {user.name},
                  </Typography>
                  }
                  <Typography
                    variant="h5"
                    color="textPrimary"
                    style={closePopup && user ?{
                      fontSize: '13px',
                      lineHeight: 1.5,
                      opacity: 0.6
                    } : {}}
                  >
                    Enter a new password for this account
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={closePopup? 12 : 6} //set to full width if closePopup is defined, because this must be the popup
                  sm={12}
                  xs={12}
                >
                  <TextField
                    error={Boolean(touched.password && errors.password)}
                    fullWidth
                    helperText={touched.password && errors.password}
                    label="Password"
                    name="password"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="password"
                    value={values.password}
                    variant="outlined"
                  />
                </Grid>
                <Grid
                  item
                  md={closePopup? 12 : 6} //set to full width if closePopup is defined, because this must be the popup
                  sm={12}
                  xs={12}
                >
                  <TextField
                    error={Boolean(touched.passwordConfirm && errors.passwordConfirm)}
                    fullWidth
                    helperText={touched.passwordConfirm && errors.passwordConfirm}
                    label="Confirm Password"
                    name="passwordConfirm"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="password"
                    value={values.passwordConfirm}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
              {errors.submit && (
                <Box mt={3}>
                  <FormHelperText error>
                    {errors.submit}
                  </FormHelperText>
                </Box>
              )}
            </CardContent>
            <Divider />
            <Box
              p={2}
              display="flex"
              justifyContent="space-between"
            >
              <Button
                color="secondary"
                disabled={isSubmitting}
                type="submit"
                variant="contained"

              >
                Change Password
              </Button>



              <Button
                color="secondary"
                disabled={isSubmitting}
                type="submit"
                variant="contained"
                onClick={handleLogout}

              >
                Logout
              </Button>

            </Box>
          </Card>
        </form>
      )}
    </Formik>
  );
};
Security.propTypes = {
  className: PropTypes.string
};
export default Security;