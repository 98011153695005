import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';
import type { AppThunk } from 'src/store'
import { apiConfig } from 'src/config';
import axios from 'src/utils/axios';
import type { Property } from 'src/types/property';
import numeral from 'numeral';
import { QueryBuilder } from '@material-ui/icons';

interface PropertyState {
 currentTab
};

const initialState: PropertyState = {
    currentTab: "details"
};

const slice = createSlice({
  name: 'property',
  initialState,
  reducers: {
    setCurrentTabRedux(state: PropertyState, action: PayloadAction<{tabName : string}>) {
      state.currentTab = action.payload.tabName;
      return state;
    }
}});

export const reducer = slice.reducer;

export const setCurrentTabRedux = (tabName: string): AppThunk => async (dispatch) => {
    dispatch(slice.actions.setCurrentTabRedux({tabName}));
};

export default slice;
