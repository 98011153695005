import React, { Suspense, Fragment, lazy } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
// import DocsLayout from 'src/layouts/DocsLayout';
import MainLayout from 'src/layouts/MainLayout';
import HomeView from 'src/views/home/HomeView';
import LoadingScreen from 'src/components/LoadingScreen';
import AuthGuard from 'src/components/AuthGuard';
import GuestGuard from 'src/components/GuestGuard';
import PasswordResetDialog from 'src/components/PasswordResetDialog';

type Routes = {
  exact?: boolean;
  path?: string | string[];
  guard?: any;
  layout?: any;
  component?: any;
  routes?: Routes;
}[];

export const renderRoutes = (routes: Routes = []): JSX.Element => {
  return (
    <Suspense fallback={<LoadingScreen />}>
      <Switch>
        {routes.map((route, i) => {
          const Guard = route.guard || Fragment;
          const Layout = route.layout || Fragment;
          const Component = route.component;

          return (
            <Route
              key={i}
              path={route.path}
              exact={route.exact}
              render={(props) => (
                <Guard>
                  <PasswordResetDialog>
                    {' '}
                    {/* This guards against users who need to reset their passwords before viewing the application. Other guards will also get implemented in a similar fashion, if required. */}
                    <Layout>
                      {route.routes ? (
                        renderRoutes(route.routes)
                      ) : (
                        <Component {...props} />
                      )}
                    </Layout>
                  </PasswordResetDialog>
                </Guard>
              )}
            />
          );
        })}
      </Switch>
    </Suspense>
  );
};

const routes: Routes = [
  // {
  //   exact: true,
  //   path: '/404',
  //   component: lazy(() => import('src/views/errors/NotFoundView'))
  // },
  {
    exact: true,
    guard: GuestGuard,
    path: '/login',
    component: lazy(() => import('src/views/auth/LoginView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/login/:loginToken',
    component: lazy(() => import('src/views/auth/LoginView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/forgot-password',
    component: lazy(() => import('src/views/auth/ForgotPasswordView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/expiredUser/:fname',
    component: lazy(() => import('src/views/expiredUser'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/complete-signup',
    component: lazy(() => import('src/views/pricing/CompleteSignupView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/failed-signup',
    component: lazy(() => import('src/views/pricing/CompleteSignupView'))
  },
  {
    exact: true,
    path: '/login-unprotected',
    component: lazy(() => import('src/views/auth/LoginView'))
  },
  // {
  //   exact: true,
  //   guard: GuestGuard,
  //   path: '/register',
  //   component: lazy(() => import('src/views/auth/RegisterView'))
  // },
  // {  // keep around for later reference
  //   exact: true,
  //   path: '/register-unprotected',
  //   component: lazy(() => import('src/views/auth/RegisterView'))
  // },
  {
    path: '/app',
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: ['/app/account', '/app/account/:tab'],
        component: lazy(() => import('src/views/account/AccountView'))
      },
      {
        exact: true,
        path: '/app/calendar',
        component: lazy(() => import('src/views/calendar/CalendarView'))
      },
      {
        exact: true,
        path: ['/app/chat/new', '/app/chat/:threadKey'],
        component: lazy(() => import('src/views/chat/ChatView'))
      },
      {
        exact: true,
        path: '/app/chat',
        component: () => <Redirect to="/app/chat/new" />
      },
      {
        exact: true,
        path: '/app/extra/charts/apex',
        component: lazy(() => import('src/views/extra/charts/ApexChartsView'))
      },
      {
        exact: true,
        path: '/app/extra/forms/formik',
        component: lazy(() => import('src/views/extra/forms/FormikView'))
      },
      {
        exact: true,
        path: '/app/extra/forms/redux',
        component: lazy(() => import('src/views/extra/forms/ReduxFormView'))
      },
      {
        exact: true,
        path: '/app/extra/editors/draft-js',
        component: lazy(() => import('src/views/extra/editors/DraftEditorView'))
      },
      {
        exact: true,
        path: '/app/extra/editors/quill',
        component: lazy(() => import('src/views/extra/editors/QuillEditorView'))
      },
      {
        exact: true,
        path: '/app/kanban',
        component: lazy(() => import('src/views/kanban/KanbanView'))
      },
      {
        exact: true,
        path: '/app/landing',
        component: lazy(() => import('src/views/landing'))
      },
      {
        exact: true,
        path: '/app',
        component: lazy(() => import('src/views/landing'))
      },
      {
        exact: true,
        path: [
          '/app/mail/label/:customLabel/:mailId?',
          '/app/mail/:systemLabel/:mailId?'
        ],
        component: lazy(() => import('src/views/mail/MailView'))
      },
      {
        exact: true,
        path: '/app/mail',
        component: () => <Redirect to="/app/mail/all" />
      },
      {
        exact: true,
        path: '/app/management/alerts',
        component: lazy(
          () => import('src/views/property/PropertyAlerts/ListView/index')
        )
      },
      {
        exact: true,
        path: '/app/management/alerts/view/:searchId',
        component: lazy(
          () => import('src/views/property/PropertyAlerts/DetailedView/index')
        )
      },
      {
        exact: true,
        path: '/app/management/alerts/edit/:searchId',
        component: lazy(
          () => import('src/views/property/PropertyAlerts/EditView/index')
        )
      },
      {
        exact: true,
        path: '/app/management/alerts/create',
        component: lazy(
          () => import('src/views/property/PropertyAlerts/CreateView/index')
        )
      },
      {
        exact: true,
        path: '/app/research/properties/map',
        component: lazy(() => import('src/views/property/MapSearch/index'))
      },
      {
        exact: true,
        path: '/app/research/properties',
        component: lazy(
          () => import('src/views/property/PropertySearchListView')
        )
      },
      {
        exact: true,
        path: '/app/research/needs',
        component: lazy(() => import('src/views/needs/NeedSearchListView'))
      },
      {
        exact: true,
        path: '/app/research/needs/:needId',
        component: lazy(() => import('src/views/needs/NeedSearchDetailsView'))
      },
      {
        exact: true,
        path: '/app/research/savedListings',
        component: lazy(() => import('src/views/property/SavedListings'))
      },
      {
        exact: true,
        path: '/app/research/savedListings/:id',
        component: lazy(
          () => import('src/views/property/SavedListings/SavedListingsView')
        )
      },
      {
        exact: true,
        path: '/app/management/properties',
        component: lazy(
          () => import('src/views/property/PropertyManagementListView')
        )
      },
      {
        exact: true,
        path: '/app/management/update',
        component: lazy(() => import('src/views/property/RliUpdateMembership'))
      },
      // Commenting this out because a property is not created when a user hits `+ New Property` button which takes them to the edit page
      // {
      //   exact: true,
      //   path: '/app/management/properties/create',
      //   component: lazy(() => import('src/views/property/PropertyCreate'))
      // },
      {
        exact: true,
        path: '/app/management/quickGenerateFeeds',
        component: lazy(() => import('src/views/feeds/QuickGenerate'))
      },
      {
        exact: true,
        path: '/app/management/feeds',
        component: lazy(
          () => import('src/views/feeds/FeedsManagementView/FeedListingView')
        )
      },
      {
        exact: true,
        path: '/app/management/feeds/:page/edit',
        component: lazy(
          () => import('src/views/feeds/FeedsManagementView/FeedListingEdit')
        )
      },
      {
        exact: true,
        path: '/app/management/needs',
        component: lazy(() => import('src/views/needs/NeedManagementListView'))
      },
      {
        exact: true,
        path: '/app/management/needs/:needId',
        component: lazy(
          () => import('src/views/needs/NeedManagementDetailsView')
        )
      },
      {
        exact: true,
        path: '/app/research/need/create',
        component: lazy(() => import('src/views/needs/NeedSearchDetailsView'))
      },
      {
        exact: true,
        path: '/app/research/properties/:propertyId',
        component: lazy(
          () => import('src/views/property/PropertySearchDetailsView')
        )
      },
      {
        exact: true,
        path: '/app/management/properties/:propertyId',
        component: lazy(
          () => import('src/views/property/PropertyManagementDetailsView')
        )
      },
      {
        exact: true,
        path: '/app/research/properties/:propertyId/edit',
        component: lazy(
          () => import('src/views/property/PropertySearchEditView')
        )
      },
      {
        exact: true,
        path: '/app/management/properties/:propertyId/edit',
        component: lazy(
          () => import('src/views/property/PropertyManagementEditView')
        )
      },
      {
        exact: true,
        path: '/app/research/members',
        component: lazy(() => import('src/views/member/MemberListView'))
      },
      {
        exact: true,
        path: '/app/research/members/:memberId',
        component: lazy(() => import('src/views/member/MemberDetailsView'))
      },
      {
        exact: true,
        path: '/app/resources/ambassadors',
        component: lazy(() => import('src/views/ambassador/AmbassadorListView'))
      },
      {
        exact: true,
        path: '/app/resources/ambassadors/:memberId',
        component: lazy(() => import('src/views/member/MemberDetailsView'))
      },
      {
        exact: true,
        path: '/app/resources/customsearch',
        component: lazy(() => import('src/views/customsearch/index'))
      },
      {
        exact: true,
        path: '/app/resources/aichat',
        component: lazy(() => import('src/views/pages/chat/index'))
      },
      {
        exact: true,
        path: '/app/research/member/:memberId/edit',
        component: lazy(() => import('src/views/member/MemberEditView'))
      },
      {
        exact: true,
        path: '/app/management/invoices',
        component: lazy(() => import('src/views/invoice/InvoiceListView'))
      },
      {
        exact: true,
        path: '/app/management/invoices/:invoiceId',
        component: lazy(() => import('src/views/invoice/InvoiceDetailsView'))
      },
      {
        exact: true,
        path: '/app/management/orders',
        component: lazy(() => import('src/views/order/OrderListView'))
      },
      {
        exact: true,
        path: '/app/management/orders/:orderId',
        component: lazy(() => import('src/views/order/OrderDetailsView'))
      },
      {
        exact: true,
        path: '/app/sales/leads',
        component: lazy(() => import('src/views/leads/LeadListView'))
      },
      {
        exact: true,
        path: '/app/sales/leads/:brokerId/:leadId',
        component: lazy(() => import('src/views/leads/LeadDetailsView'))
      },
      {
        exact: true,
        path: '/app/sales',
        component: () => <Redirect to="/app/sales/leads" />
      },
      {
        exact: true,
        path: '/app/management/needs',
        component: lazy(() => import('src/views/needs/NeedsListView'))
      },
      {
        exact: true,
        path: '/app/management/needs/:needId/edit',
        component: lazy(() => import('src/views/needs/NeedManagementEditView'))
      },
      {
        exact: true,
        path: '/app/management/need/create',
        component: lazy(() => import('src/views/needs/NeedsCreateView'))
      },
      {
        exact: true,
        path: '/app/management/products',
        component: lazy(() => import('src/views/product/ProductListView'))
      },
      {
        exact: true,
        path: '/app/management/products/create',
        component: lazy(() => import('src/views/product/ProductCreateView'))
      },
      {
        exact: true,
        path: '/app/research',
        component: () => <Redirect to="/app/research/members" />
      },
      {
        exact: true,
        path: '/app/projects/overview',
        component: lazy(() => import('src/views/project/OverviewView'))
      },
      {
        exact: true,
        path: '/app/projects/browse',
        component: lazy(() => import('src/views/project/ProjectBrowseView'))
      },
      {
        exact: true,
        path: '/app/projects/create',
        component: lazy(() => import('src/views/project/ProjectCreateView'))
      },
      {
        exact: true,
        path: '/app/projects/:id',
        component: lazy(() => import('src/views/project/ProjectDetailsView'))
      },
      {
        exact: true,
        path: '/app/projects',
        component: () => <Redirect to="/app/projects/browse" />
      },
      {
        exact: true,
        path: '/app/reports/property-statistics',
        component: lazy(() => import('src/views/reports/PropertyStatistics'))
      },
      {
        exact: true,
        path: '/app/reports/personal-statistics',
        component: lazy(() => import('src/views/reports/PersonalStatistics'))
      },
      {
        exact: true,
        path: '/app/reports/brokerage-statistics',
        component: lazy(() => import('src/views/reports/BrokerageStatistics'))
      },
      {
        exact: true,
        path: '/app/reports/global-statistics',
        component: lazy(() => import('src/views/reports/GlobalStatistics'))
      },
      {
        exact: true,
        path: '/app/reports',
        component: () => <Redirect to="/app/reports/dashboard" />
      },
      {
        exact: true,
        path: '/app/social/feed',
        component: lazy(() => import('src/views/social/FeedView'))
      },
      {
        exact: true,
        path: '/app/social/profile',
        component: lazy(() => import('src/views/social/ProfileView'))
      },
      {
        exact: true,
        path: '/app/features/placeholder',
        component: lazy(() => import('src/views/comingSoon/index'))
      },
      {
        exact: true,
        path: '/app/resources/benefits',
        component: lazy(() => import('src/views/pages/memberBenefits/index'))
      },
      {
        exact: true,
        path: '/app/resources/contact',
        component: lazy(() => import('src/views/pages/contact/index'))
      },
      {
        exact: true,
        path: '/app/resources/insurance',
        component: lazy(() => import('src/views/pages/insurance/index'))
      },
      {
        exact: true,
        path: '/app/resources/realx',
        component: lazy(() => import('src/views/pages/realx/index'))
      },
      {
        exact: true,
        path: '/app/research/compdata',
        component: lazy(() => import('src/views/pages/compdata/index'))
      },
      {
        exact: true,
        path: '/app/comingSoon',
        component: lazy(() => import('src/views/comingSoon/index'))
      },
      {
        exact: true,
        path: '/app/social',
        component: () => <Redirect to="/app/social/profile" />
      },
      {
        exact: true,
        path: '/app/management',
        component: () => <Redirect to="/app/management/properties" />
      },
      {
        exact: true,
        path: '/app',
        component: () => <Redirect to="/app/reports/dashboard" />
      },
      {
        component: () => <Redirect to="/login" />
      }
    ]
  },
  // {
  //   path: '/docs',
  //   layout: DocsLayout,
  //   routes: [
  //     {
  //       exact: true,
  //       path: '/docs',
  //       component: () => <Redirect to="/docs/welcome" />
  //     },
  //     {
  //       exact: true,
  //       path: '/docs/welcome',
  //       component: lazy(() => import('src/views/docs/WelcomeView'))
  //     },
  //     {
  //       exact: true,
  //       path: '/docs/getting-started',
  //       component: lazy(() => import('src/views/docs/GettingStartedView'))
  //     },
  //     {
  //       exact: true,
  //       path: '/docs/environment-variables',
  //       component: lazy(() => import('src/views/docs/EnvironmentVariablesView'))
  //     },
  //     {
  //       exact: true,
  //       path: '/docs/deployment',
  //       component: lazy(() => import('src/views/docs/DeploymentView'))
  //     },
  //     {
  //       exact: true,
  //       path: '/docs/api-calls',
  //       component: lazy(() => import('src/views/docs/APICallsView'))
  //     },
  //     {
  //       exact: true,
  //       path: '/docs/analytics',
  //       component: lazy(() => import('src/views/docs/AnalyticsView'))
  //     },
  //     {
  //       exact: true,
  //       path: '/docs/authentication',
  //       component: lazy(() => import('src/views/docs/AuthenticationView'))
  //     },
  //     {
  //       exact: true,
  //       path: '/docs/routing',
  //       component: lazy(() => import('src/views/docs/RoutingView'))
  //     },
  //     {
  //       exact: true,
  //       path: '/docs/settings',
  //       component: lazy(() => import('src/views/docs/SettingsView'))
  //     },
  //     {
  //       exact: true,
  //       path: '/docs/state-management',
  //       component: lazy(() => import('src/views/docs/StateManagementView'))
  //     },
  //     {
  //       exact: true,
  //       path: '/docs/theming',
  //       component: lazy(() => import('src/views/docs/ThemingView'))
  //     },
  //     {
  //       exact: true,
  //       path: '/docs/support',
  //       component: lazy(() => import('src/views/docs/SupportView'))
  //     },
  //     {
  //       exact: true,
  //       path: '/docs/changelog',
  //       component: lazy(() => import('src/views/docs/ChangelogView'))
  //     },
  //     {
  //       component: () => <Redirect to="/404" />
  //     }
  //   ]
  // },
  {
    path: '*',
    layout: MainLayout,
    routes: [
      {
        exact: true,
        path: '/',
        component: HomeView
      },
      {
        exact: true,
        path: '/pricing',
        component: lazy(() => import('src/views/pricing/PricingView'))
      },
      {
        exact: false,
        path: '/pricing/:companyId/subagent',
        component: lazy(() => import('src/views/pricing/PricingView'))
      },
      {
        exact: false,
        path: '/pricing/affiliate',
        component: lazy(() => import('src/views/pricing/PricingView'))
      },
      {
        exact: true,
        path: '/pricing/:companyName/broker',
        component: lazy(() => import('src/views/pricing/PricingView'))
      },
      // {
      //   exact: true,
      //   path: '/pricing/broker/eandv',
      //   component: lazy(() => import('src/views/pricing/PricingViewEandv')) //Set as different than the others above
      // },

      {
        component: () => <Redirect to="/login" />
      }
    ]
  }
];

export default routes;
