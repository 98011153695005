import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';
import type { AppThunk } from 'src/store'
import { apiConfig } from 'src/config';
import axios from 'src/utils/axios';

interface GlobalStatisticsState {
    averageSalePrice,
    withdrawnExpired,
    activeListings,
    closedSales,
    listPriceOverTime: {
        data,
        labels
    },
    newListingsOverTime: {
        data,
        labels
    },
    filters: {
        state,
        county,
        time,
    },
    timeRange,
    state,
    county
}

const initialState: GlobalStatisticsState = {
    averageSalePrice: null,
    withdrawnExpired: null,
    activeListings: null,
    closedSales: null,
    listPriceOverTime: {
        data: null,
        labels: null
    },
    newListingsOverTime: {
        data: null,
        labels: null
    },
    filters: {
        state: null,
        county: null,
        time: null,
    },
    timeRange: null,
    state: null,
    county: null
};

const slice = createSlice({
    name: 'globalStatistics',
    initialState,
    reducers: {

        getGlobalAverageSalePrice(state: GlobalStatisticsState, action: PayloadAction<{ averageSalePrice }>) {
            let data = action.payload;
            state = { ...state, averageSalePrice: data.averageSalePrice }
            return state;
        },

        getGlobalWithdrawnExpired(state: GlobalStatisticsState, action: PayloadAction<{ withdrawnExpired }>) {
            let data = action.payload;
            state = { ...state, withdrawnExpired: data.withdrawnExpired }
            return state;
        },

        getGlobalActiveListings(state: GlobalStatisticsState, action: PayloadAction<{ activeListings }>) {
            let data = action.payload;
            state = { ...state, activeListings: data.activeListings }
            return state;
        },

        getGlobalClosedSales(state: GlobalStatisticsState, action: PayloadAction<{ closedSales }>) {
            let data = action.payload;
            state = { ...state, closedSales: data.closedSales }
            return state;
        },

        getGlobalListPriceOverTime(state: GlobalStatisticsState, action: PayloadAction<{ listPriceOverTime }>) {
            let data = action.payload;
            state = {
                ...state,
                listPriceOverTime: {
                    data: data.listPriceOverTime.data,
                    labels: data.listPriceOverTime.labels
                },
            }
            return state;
        },

        getGlobalNewListingsOverTime(state: GlobalStatisticsState, action: PayloadAction<{ newListingsOverTime }>) {
            let data = action.payload;
            state = {
                ...state,
                newListingsOverTime: {
                    data: data.newListingsOverTime.data,
                    labels: data.newListingsOverTime.labels
                },
            }
            return state;
        },

        setGlobalFilters(state: GlobalStatisticsState, action: PayloadAction<{ filters }>) {
            const data = action.payload;

            state = {
                ...state,
                filters: {
                    state: data.filters.state,
                    county: data.filters.county,
                    time: data.filters.time,
                }
            }

            return state;
        },
        setGlobalTimeRange(state: GlobalStatisticsState, action: PayloadAction<string>) {
            state.timeRange = action.payload
            return state;
        },
        setGlobalState(state: GlobalStatisticsState, action: PayloadAction<string>) {
            state.state = action.payload
            return state;
        },
        setGlobalCounty(state: GlobalStatisticsState, action: PayloadAction<string>) {
            state.county = action.payload
            return state;
        },
        resetGlobalStatsState(state: GlobalStatisticsState, action: PayloadAction<string>) {
            state[action.payload] = {
                ...state[action.payload],
                averageSalePrice: undefined,
                withdrawnExpired: undefined,
                activeListings: undefined,
                closedSales: undefined,
                listPriceOverTime: {
                    data: undefined,
                    labels: undefined
                },
                newListingsOverTime: {
                    data: undefined,
                    labels: undefined
                }
            };
            return state
        }
    },
});

export const reducer = slice.reducer;

export const getGlobalAverageSalePrice = (state: GlobalStatisticsState): AppThunk => async (dispatch) => {
    let averageSaleRes = await axios.get<{ stats: { data } }>(`//${apiConfig.api_prefix}/statistics/averageListPrice`, {
        params: {
            method: "global",
            dateRange: `${state.timeRange}`,
            state: `${state.state}`,
            county: `${state.county}`
        }
    });

    dispatch(slice.actions.getGlobalAverageSalePrice({ averageSalePrice: averageSaleRes.data.stats.data }));
};

export const getGlobalWithdrawnExpired = (state: GlobalStatisticsState): AppThunk => async (dispatch) => {
    let expiredRes = await axios.get<{ stats: { data } }>(`//${apiConfig.api_prefix}/statistics/expired`, {
        params: {
            method: "global",
            dateRange: `${state.timeRange}`,
            state: `${state.state}`,
            county: `${state.county}`
        }
    });
    let withdrawnRes = await axios.get<{ stats: { data } }>(`//${apiConfig.api_prefix}/statistics/withdrawn`, {
        params: {
            method: "global",
            dateRange: `${state.timeRange}`,
            state: `${state.state}`,
            county: `${state.county}`
        }
    });

    dispatch(slice.actions.getGlobalWithdrawnExpired({ withdrawnExpired: expiredRes.data.stats.data + withdrawnRes.data.stats.data }));
};

export const getGlobalActiveListings = (state: GlobalStatisticsState): AppThunk => async (dispatch) => {
    let activeListingsRes = await axios.get<{ stats: { data } }>(`//${apiConfig.api_prefix}/statistics/activeListings`, {
        params: {
            method: "global",
            dateRange: `${state.timeRange}`,
            state: `${state.state}`,
            county: `${state.county}`
        }
    });

    dispatch(slice.actions.getGlobalActiveListings({ activeListings: activeListingsRes.data.stats.data, }));
};

export const getGlobalClosedSales = (state: GlobalStatisticsState): AppThunk => async (dispatch) => {
    let closedRes = await axios.get<{ stats: { data } }>(`//${apiConfig.api_prefix}/statistics/closedSales`, {
        params: {
            method: "global",
            dateRange: `${state.timeRange}`,
            state: `${state.state}`,
            county: `${state.county}`
        }
    });

    dispatch(slice.actions.getGlobalClosedSales({ closedSales: closedRes.data.stats.data }));
};

export const getGlobalListPriceOverTime = (state: GlobalStatisticsState): AppThunk => async (dispatch) => {
    let priceOverTimeRes = await axios.get<{ stats: { data, labels }; }>(`//${apiConfig.api_prefix}/statistics/propertyListPrice`, {
        params: {
            method: "global",
            dateRange: `${state.timeRange}`,
            state: `${state.state}`,
            county: `${state.county}`
        }
    });

    dispatch(slice.actions.getGlobalListPriceOverTime({
        listPriceOverTime: {
            data: priceOverTimeRes.data.stats.data,
            labels: priceOverTimeRes.data.stats.labels,
        },
    }));
};

export const getGlobalNewListingsOverTime = (state: GlobalStatisticsState): AppThunk => async (dispatch) => {
    let newListingsRes = await axios.get<{ stats: { data, labels }; }>(`//${apiConfig.api_prefix}/statistics/activeListingsOverTime`, {
        params: {
            method: "global",
            dateRange: `${state.timeRange}`,
            state: `${state.state}`,
            county: `${state.county}`
        }
    });

    dispatch(slice.actions.getGlobalNewListingsOverTime({
        newListingsOverTime: {
            data: newListingsRes.data.stats.data,
            labels: newListingsRes.data.stats.labels,
        }
    }));
};

export const setGlobalTimeRange = (timeRange: string, index: number): AppThunk => async (dispatch, getState) => {
    dispatch(slice.actions.setGlobalTimeRange(timeRange));
    dispatch(slice.actions.setGlobalFilters({
        filters: {
            time: index,
            state: getState().globalStatistics.filters.state,
            county: getState().globalStatistics.filters.county
        }
    }));
    dispatch(slice.actions.resetGlobalStatsState('global'));
    reset(dispatch, getState);
};

export const setGlobalState = (state: string, stateFilter: any): AppThunk => async (dispatch, getState) => {
    dispatch(slice.actions.setGlobalState(state));
    dispatch(slice.actions.setGlobalFilters({
        filters: {
            time: getState().globalStatistics.filters.time || 2,
            state: stateFilter,
            county: getState().globalStatistics.filters.county
        }
    }));
    dispatch(slice.actions.resetGlobalStatsState('global'));
    reset(dispatch, getState);
};
export const setGlobalCounty = (county: string, countyFilter: any): AppThunk => async (dispatch, getState) => {
    dispatch(slice.actions.setGlobalCounty(county));
    dispatch(slice.actions.setGlobalFilters({
        filters: {
            time: getState().globalStatistics.filters.time || 2,
            state: getState().globalStatistics.filters.state,
            county: countyFilter
        }
    }));
    dispatch(slice.actions.resetGlobalStatsState('global'));
    reset(dispatch, getState);
};

const reset = (dispatch, getState) => {
    dispatch(getGlobalAverageSalePrice(getState().globalStatistics));
    dispatch(getGlobalWithdrawnExpired(getState().globalStatistics));
    dispatch(getGlobalActiveListings(getState().globalStatistics));
    dispatch(getGlobalClosedSales(getState().globalStatistics));
    dispatch(getGlobalListPriceOverTime(getState().globalStatistics));
    dispatch(getGlobalNewListingsOverTime(getState().globalStatistics));
};

export default slice;
