import React, { useState, useEffect } from 'react';
import { Typography, makeStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import axios from 'src/utils/axios';
import { apiConfig } from 'src/config';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles((theme) => ({
    root: {},
    denyButton: {
        backgroundColor: "red",
        color: "white"
    }
}));


export default function AgentRequestDialog(props) {
    const classes = useStyles();
    const { onClose, open, user, ...rest } = props;
    const { enqueueSnackbar } = useSnackbar();
    const [requestingUser, setRequestingUser] = useState(user.invitedAsAgentByUserId);

    useEffect(() => {
        if (user.invitedAsAgentByUserId) {
            axios.get(`//${apiConfig.api_prefix}/user/${user.invitedAsAgentByUserId}`
            ).then(response => {
                setRequestingUser(response.data.member.fname + " " + response.data.member.lname);
            }).catch(err => {
                console.log("Error while getting requester.");
            });
        }
    }, [user])

    const handleCancel = () => {
        //close popup. Will reappear next time sidebar reloads.
        onClose();
    };

    const handleDeny = () => {
        axios.put(`//${apiConfig.api_prefix}/user/${user.invitedAsAgentByUserId}/acknowledgeRequest`, {
            action: 'reject'
        }
        ).then(response => {
            enqueueSnackbar('Request Denied', {
                variant: 'success',
                autoHideDuration: 5000
            });
        }).catch(err => {
            enqueueSnackbar('Error while Denying Request', {
                variant: 'error',
                autoHideDuration: 5000
            });
        });
        onClose();
    };

    const handleOk = () => {
        axios.put(`//${apiConfig.api_prefix}/user/${user.invitedAsAgentByUserId}/acknowledgeRequest`, {
            action: 'accept'
        }
        ).then(response => {
            enqueueSnackbar('Request Accepted', {
                variant: 'success',
                autoHideDuration: 5000
            });
        }).catch(err => {
            enqueueSnackbar('Error while Accepting Request', {
                variant: 'error',
                autoHideDuration: 5000
            });
        });
        onClose();
    };


    return (
        <Dialog
            disableBackdropClick
            disableEscapeKeyDown
            maxWidth="sm"
            aria-labelledby="confirmation-dialog-title"
            open={open}
        >
            <DialogTitle id="confirmation-dialog-title">Brokerage-Agent Connection Request</DialogTitle>
            <DialogContent dividers>
                <Typography variant="body1">
                    User {requestingUser} is trying to add this account as a subagent.
                </Typography>
                <Typography variant="body1" style={{ marginTop: "12px" }}>
                    This will allow them to act as your user, view all property information, and the action cannot be reversed.
                </Typography>
                <Typography variant="body1" style={{ marginTop: "12px" }}>
                    Would you like to allow them brokerage access to this account?
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancel}>
                    Close
                </Button>
                <Button onClick={handleDeny} variant="contained" className={classes.denyButton}>
                    Deny Request
                </Button>
                <Button onClick={handleOk} variant="contained" color="primary">
                    Accept Request
                </Button>
            </DialogActions>
        </Dialog>
    );
}