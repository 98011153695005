import React, { useState, useEffect } from 'react';
import { Typography, makeStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import axios from 'src/utils/axios';
import { useHistory } from 'react-router-dom';
import Security from '../views/account/AccountView/Security';
import useAuth from 'src/hooks/useAuth';

const useStyles = makeStyles((theme) => ({
    root: {},
    denyButton: {
        backgroundColor: "red",
        color: "white"
    }
}));

/**
 *  Password Reset Statuses:
 * 
 *  0: Does not need to reset password
 *  1: First time logging in. Reset password and redirect to /app/account to set rest of account information if desired.
 *  2: Forced reset from another source. Login as normal after resetting password.
 * 
 */


export default function PasswordResetDialog({children}) {
    const classes = useStyles();
    const { user, login } = useAuth();
    const [resetPassword, setResetPassword] = React.useState(user && user.resetPassword);
    const handleClose = async (newPass) => {
        await login(user.email, newPass); //Login user with refreshed token
        setResetPassword(0);
    }
    const history = useHistory();

    if(resetPassword){
        if(resetPassword === 1 && window.location.pathname !== "/app/account"){
            history.push('/app/account'); //If it's their first time logging in, push them to account settings to set other details if they want to.
        }
        return (
            <Dialog
                disableBackdropClick
                disableEscapeKeyDown
                maxWidth="xs"
                aria-labelledby="confirmation-dialog-title"
                open={resetPassword}
            >
                <Security closePopup={handleClose} user={user} />
            </Dialog>
        );
    }

    else return (
        <>
            {children}
        </>
    )
}